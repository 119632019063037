import {Loader, Tab} from "semantic-ui-react";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Questions from "./questions";

function TemplateTab() {
  const uuid = useParams().tempUuid;
  const [exp, setExp] = useState();
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/template/${uuid}`, {withCredentials: true});
    await setExp(res.data);
    await setIsLoaded(true)
  }, [])

  return <>
    {isLoaded ?
      <>
        <h1>{exp.title}</h1>
        <Tab menu={{secondary: true, pointing: true}} panes={
          [
            {
              menuItem: 'question',
              render: () => <Questions exp={exp}/>
            },
          ]
        }/>
      </> :
      <Loader active inline={'centered'}/>
    }
  </>
}

export default TemplateTab;