import {Component} from "react";
import {Divider, Loader, Progress, Segment} from "semantic-ui-react";
import AddExperiment from "./add-experiment";
import axios from "axios";
import AddExperimentId from "./add-experiment-id";
import { FormattedMessage } from "react-intl";


class Lookup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      userName: "test_user_001",
      expList: [],
    }
  }

  loadData = async () => {
    try{
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/experiment`, {withCredentials: true})
      const temp = res.data
      this.setState({
        isLoaded: true,
        expList: temp
      })
    } catch (e) {
      alert('로그인을 해주세요!')
      window.location.href='/account/login'
    }
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    return (
      <div>
        <h1 className='mb-8'><FormattedMessage id='experiment_list_title'/></h1>
        {
          this.state.isLoaded ? <>
            {this.state.expList.map(data => (
              <Segment className={'pointer'} onClick={() => window.location.href = `/experiment/${data.uuid}`}>
                <h3>{data.title}</h3>
                <Divider/>
                {/*팀원: {data.users.map((name)=>(name + " "))}<br/><br/>*/}
                <p>{data.description}</p>
                <Progress className='mb-5' percent={data.report_progress} indicating progress='percent'/>
                <p className={'has-text-right'}><FormattedMessage id='experiment_code_title'/> : {data.experiment_id}</p>
              </Segment>
            ))}
            <div className={'mv-8'}>
              <AddExperiment reload={this.loadData}/>
              <AddExperimentId reload={this.loadData}/>
            </div>
          </> :
            <Loader active inline={'centered'}/>
        }

      </div>
    )
  }
}

export default Lookup;