import {Button, Form, Header, Icon, Input, Modal, Select, TextArea} from "semantic-ui-react";
import {useEffect, useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";
import jwtDecode from "jwt-decode";
import { FormattedMessage } from "react-intl";


let options = [{},]

function AddExperimentId(props){
    const [open, setOpen] = useState(false);
    const [title, setTitle, removeTitle] = useState("");
    const [explain, setExplain, removeExplain] = useState("");
    const [id, setId, removeId] = useState();
    const [uuid, setUuid, removeUuid] = useState();
    const [login, setLogin, removeLogin] = useCookies(['Authentication']);

    const handleId = (e, data) =>{
        setId(data.value);
    }

    useEffect(
        async ()=>{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/template`,
                {
                    withCredentials: true
                })
            const temp = res.data
            let list = [];
            await temp.map((item,index)=>{
                list[index] = {key: index, text: item.title, value: item.uuid};
            })
            options = list
        }, []
    )

    const uploadData = async () => {
        const user_data = jwtDecode(login.token)
        await axios.put(`${process.env.REACT_APP_API_URL}/experiment/participant`, {
            user_uuid: user_data.user.uuid,
            experiment_uuid: uuid
        },{withCredentials: true})
        setOpen(false)
        setExplain("")
        setId("")
        setTitle("")
        props.reload()
    }

    const loadData = async() =>{
        await axios.get(`${process.env.REACT_APP_API_URL}/experiment/id/${id}`,{withCredentials:true}).then(res=>{
            if(res.status===200){
                console.log(res)
                setTitle(res.data.title)
                setExplain(res.data.description)
                setUuid(res.data.uuid)
            }
        }).catch(err=>{
            alert("실험을 조회할 수 없습니다. 실험 코드를 다시 확인해주세요")
            setTitle("")
            setExplain("")
        })
    }



    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button basic className='pd-1'><Icon name='handshake'/> <FormattedMessage id='join_experiment_button'/></Button>}
        >
            <Modal.Header><FormattedMessage id='join_experiment_title'/></Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header><FormattedMessage id='enter_info_title'/></Header>
                    <p><FormattedMessage id='join_experiment_content'/></p>
                    <Form className={'mt-6'}>
                        <Form.Field label={<FormattedMessage id='experiment_code'/>} control={Input} options={options} placeholder="실험 코드를 입력하세요." onChange={handleId}/>
                        <Button onClick={loadData}><Icon name='search'/> <FormattedMessage id='experiment_search'/></Button>
                        <Form.Field readOnly fluid label={<FormattedMessage id='experiment_title'/>} control={Input} value={title} placeholder="실험 코드를 조회하면 데이터가 로드됩니다." className={'pv-5 mt-5'} readonly/>
                        <Form.Field readOnly fluid label={<FormattedMessage id='experiment_explanation'/>} control={TextArea} value={explain} placeholder="실험 코드를 조회하면 데이터가 로드됩니다."  className={'mb-3'} style={{minHeight:200}}/>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                    <FormattedMessage id='cancel_button'/>
                </Button>
                <Button
                    content={<FormattedMessage id='add_button'/>}
                    onClick={()=>{uploadData()}}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default AddExperimentId;