import React, {Component} from "react";
import {
  Button,
  Divider,
  Form,
  Grid,
  Icon,
  Input,
  Loader, Progress,
  Segment,
  TextArea
} from "semantic-ui-react";
import axios from "axios";
import Profile from "../../utils/profile";
import {FormattedMessage} from "react-intl";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import jwt_decode from "jwt-decode";
import { saveAs } from "file-saver";
import {Document, Paragraph, Packer, HeadingLevel, ImageRun} from "docx";
import moment from "moment";

class Main extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {notices: [], isLoaded: false, user: jwt_decode(cookies.get('token')).user, answers: []}
  }

  async componentDidMount() {
    const notices = await axios.get(`${process.env.REACT_APP_API_URL}/post/experiment/${this.props.exp.uuid}`, {withCredentials: true});
    const answers = await axios.get(`${process.env.REACT_APP_API_URL}/report/experiment/${this.props.exp.uuid}?user=${this.state.user.uuid}`, {withCredentials: true});
    this.setState({
      notices: notices.data,
      isLoaded: true,
      answers: answers.data
    });
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  uploadNotice = () => {
    axios.post(`${process.env.REACT_APP_API_URL}/post`, {
      title: this.state.title,
      body: this.state.content,
      experiment_uuid: this.props.exp.uuid,
    }, {withCredentials: true})
      .then(res => {
        axios.get(`${process.env.REACT_APP_API_URL}/post/experiment/${this.props.exp.uuid}`, {withCredentials: true})
          .then(res => {
            this.setState({
              notices: res.data,
              title: '',
              content: '',
            })
          });
      })
      .catch(e => {
        console.log(e);
        alert('업로드에 실패했습니다.')
      });
  }

  generateDocx = async () => {
    const ans = []

    for (let idx in this.state.answers) {
      ans.push(new Paragraph({
        text: this.state.answers[this.state.answers.length - idx - 1].answer,
      }))
      if (this.state.answers[this.state.answers.length - idx - 1].file_url) {
        const img = await axios({
          url: `${process.env.REACT_APP_API_URL_BASE}/${this.state.answers[this.state.answers.length - idx - 1].file_url}`,
          method: 'GET',
          responseType: 'arraybuffer',
        })
        ans.push(new Paragraph({
          children: [new ImageRun({
            data: Buffer.from(Buffer.from(img.data, 'binary').toString('base64'), "base64"),
            transformation: {
              width: 500,
              height: 500
            },
          })
          ]
        }))
      }
    }

    const doc = new Document({
        sections: [
        {
            children: [
                new Paragraph({
                    text: this.props.exp.title + "\n", 
                    heading: HeadingLevel.HEADING_1,
                }),
              ...ans
            ]
        }]
    });
    
    Packer.toBlob(doc).then(blob => {
        saveAs(blob, "report.docx");
    })
}

  render() {
    return (
      <div className='mt-7'>
        <h1><FormattedMessage id='notice_board'/></h1>
        <p><FormattedMessage id='notice_board_content'/></p>
        {
          this.state.isLoaded ?
            <>
              <Segment className={'mt-6'}>
                <h2>{this.props.exp.title}</h2>
                <p><FormattedMessage id='participating_teammates_title'/> : {
                  this.props.exp.user.map((user, idx) => idx === this.props.exp.user.length - 1 ? `${user.name}` : `${user.name}, `)
                }</p>
                <p><FormattedMessage id='experiment_code_title'/>: {this.props.exp.experiment_id}</p>
              </Segment>
              <Grid stackable>
                <Grid.Column width={4}>
                  <p className={'fw-b'}><FormattedMessage id='total_experiment_completion_rate'/></p>
                  <Progress className='mb-5' percent={this.props.exp.report_progress} indicating progress='percent'/>
                  <Button fluid color='teal' onClick={this.generateDocx}><Icon name={'download'}/> <FormattedMessage id='download_button'/></Button>
                </Grid.Column>
                <Grid.Column width={12}>
                  <Segment>
                    <div className='is-flex'>
                      <div style={{flexShrink: 0}}>
                        <Profile className={'mr-5'} src={this.state.user.profile_url} size={36}/>
                      </div>
                      <Form style={{flexGrow: 1}}>
                        <FormattedMessage id='notice_title_placeholder'>
                          {(msg) => (<Input fluid className='mb-5' placeholder={msg} value={this.state.title} name={'title'} onChange={this.handleChange}/>)}
                        </FormattedMessage>
                        <FormattedMessage id='notice_content_placeholder'>
                          {(msg) => (<TextArea fluid placeholder={msg} value={this.state.content} name={'content'} onChange={this.handleChange}/>)}
                        </FormattedMessage>
                      </Form>
                    </div>
                    <div className='is-flex' style={{justifyContent: 'flex-end'}}>
                      <Button onClick={this.uploadNotice} primary className='mt-5'><Icon name={'send'}/> <FormattedMessage id='upload'/></Button>
                    </div>
                  </Segment>
                  {
                    this.state.notices.map(item => (
                    <Segment>
                      <div className='has-item-vcentered'>
                        <Profile className={'mr-5'} src={item.user.profile_url} size={36}/>
                        <div>
                          <p className={'fw-b mb-0'}>{item.user.name}</p>
                          <p className='fs-m1 color-gray has-text-right'>{moment(item.created_at).format('YYYY.MM.DD. HH:mm')}</p>
                        </div>
                      </div>
                      <h3>
                        {item.title}
                      </h3>
                      <Divider/>
                      <p>{item.body}</p>
                    </Segment>
                  ))}
                </Grid.Column>
              </Grid>
            </>
            :
            <Loader inline={'centered'} active/>
        }
      </div>
    )
  }
}

export default withCookies(Main);