import {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Form, Icon, Input, Modal} from "semantic-ui-react";
import axios from "axios";
import {useCookies} from "react-cookie";

function LogIn(props) {
  const [Email, setEmail, removeId] = useState(null)
  const [Pw, setPw, removePw] = useState(null)
  const [login, setLogin, removeLogin] = useCookies(['Authentication'])
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [findPWEmail, setFindPWEmail] = useState('')
  const [isOpen, setOpen] = useState(false)

  const updateEmail = (e) => {
    setEmail(e.target.value)
  }

  const updatePw = (e) => {
    setPw(e.target.value)
  }

  const sendData = async () => {
    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(Email)) return alert('이메일 형식이 올바르지 않습니다.');

    const tk = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
      email: Email,
      password: Pw,
    })
    console.log(tk)
    if (tk.data.status === 401) {
      if (cookies.language === 'ko') {
        alert(tk.data.response.ko)
      } else {
        alert(tk.data.response.en)
      }

    } else {
      setLogin("Authentication", tk.data, {
        path: '/', httpOnly: false,
      })
      setLogin("token", tk.data, {
        path: '/', httpOnly: false,
      })
      window.location.href = "/"
    }
  }

  const changePassword = async () => {
    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(findPWEmail)) return alert('이메일 형식이 올바르지 않습니다.');

    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/user/reset-password?email=${findPWEmail}`);
      alert("비밀번호 재설정 링크를 메일로 보냈습니다. 메일함을 확인해주세요.");
      setOpen(false);
    } catch (e) {
      console.log(e)
      alert('문제가 발생했습니다.')
    }
  }

  const onKeyPress = (e) => {
    if (e.key == 'Enter') {
      sendData();
    }
  }

  const {intl} = props;
  return (
    <div>
      <h2><FormattedMessage id="login"/></h2>
      <Form className={"mv-8"}>
        <Form.Field>
          <label>Email</label>
          <input type="text" name="email" placeholder="example@example.com"
                 onChange={updateEmail}
          />
        </Form.Field>
        <Form.Field>
          <label>Password</label>
          <FormattedMessage id="input_pw">
            {text => <input type="password" placeholder={text}
                            onChange={updatePw} onKeyPress={onKeyPress}
            />}
          </FormattedMessage>
        </Form.Field>
      </Form>
      <Button onClick={sendData} className='mr-5'><Icon name={'sign-in'}/> <FormattedMessage id="login"/></Button>
      {/* 아래는 비밀번호 찾기 버튼입니다. 정책상 사용자가 사용할 수 없어 주석처리했습니다. */}
      {/* <Modal
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={<Button><Icon name={'lock open'}/> Find Password</Button>}>
        <Modal.Header>
          Find Password
        </Modal.Header>
        <Modal.Content>
          <p><FormattedMessage id={'find_password_content'}/></p>
          <Input fluid placeholder='Email' label='Email' onChange={(e) => setFindPWEmail(e.target.value)} value={findPWEmail}/>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}><FormattedMessage id={'cancel_button'}/></Button>
          <Button primary onClick={() => changePassword()}><FormattedMessage id={'find_button'}/></Button>
        </Modal.Actions>
      </Modal> */}
    </div>
  )

}

export default LogIn;