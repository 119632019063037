import React, {Component} from "react";
import {Button, Grid, Icon, Input, Loader, Segment} from "semantic-ui-react";
import axios from "axios";
import Profile from "../../utils/profile";
import {FormattedMessage} from "react-intl";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import jwt_decode from "jwt-decode";
import moment from "moment";


class Upload extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {submits: [], isLoaded: false, user: jwt_decode(cookies.get('token')).user}
  }

  async getSubmits() {
    const submits = await axios.get(`${process.env.REACT_APP_API_URL}/submit/experiment/${this.props.exp.uuid}`, {withCredentials: true});
    this.setState({
      submits: submits.data,
      isLoaded: true,
    });

  }
  async componentDidMount() {
    this.getSubmits();
  }

  handleFileClick = () => {
    document.getElementById('hiddenFileInput').click();
  };

  handleFile = async (e) => {
    this.setState({
      inputFile: e.target.value,
      file: e.target.files[0],
    })
  }

  handleUpload = () => {
    if (!this.state.file) {
      alert('먼저 업로드할 파일을 선택해주세요.')
      return;
    }

    const formData = new FormData();
    formData.append('name', this.state.file.name);
    formData.append('experiment_uuid', this.props.exp.uuid);
    formData.append('uploaded_file', this.state.file);

    axios.post(`${process.env.REACT_APP_API_URL}/submit`, formData, {withCredentials: true})
      .then(res => {
        axios.get(`${process.env.REACT_APP_API_URL}/submit/experiment/${this.props.exp.uuid}`, {withCredentials: true})
          .then(res => {
            this.setState({
              submits: res.data,
              file: null,
              inputFile: '',
            })
          });
      })
      .catch(e => {
        console.log(e);
        alert('업로드에 실패했습니다.')
      });
  }

  deleteFile = async(uuid) => {
    await axios.delete(`${process.env.REACT_APP_API_URL}/submit/own/${uuid}`, { withCredentials: true });
    this.getSubmits();
  }

  render() {
    return (
      <div className='mt-7'>
        <h1><FormattedMessage id='upload'/></h1>
        <p><FormattedMessage id='upload_explanation'/></p>
        {
          this.state.isLoaded ? <>
              <Segment className='mt-6'>
                <div className='has-item-vcentered'>
                  <div style={{flexShrink: 0}}>
                    <Profile className={'mr-5'} src={this.state.user.profile_url} size={36}/>
                  </div>
                  <Input id='hiddenFileInput' style={{width: '100%', display: 'none'}} fluid type="file" accept={'text/plain,image/*'}
                         onChange={this.handleFile} value={this.state.inputFile}/>
                  <Button onClick={this.handleFileClick}>
                    <Icon name='upload' /><FormattedMessage id='select_file'/>
                  </Button>
                  {this.state.file && <span>{this.state.file.name}</span>}
                </div>
                <div className='is-flex' style={{justifyContent: 'flex-end'}}>
                  <Button onClick={this.handleUpload} primary className='mt-5'><Icon name={'send'}/> <FormattedMessage id='upload'/></Button>
                </div>
              </Segment>
              {
                this.state.submits.map(item => <Segment>
                    <div className='has-item-vcentered'>
                      <Profile className={'mr-5'} src={item.user.profile_url} size={36}/>
                      <div>
                        <p className={'fw-b mb-0'}>{item.user.name}</p>
                        <p className='fs-m1 color-gray has-text-right'>{moment(item.created_at).format('YYYY.MM.DD. HH:mm')}</p>
                      </div>
                    </div>
                    <Grid stackable>
                      <Grid.Column width={9}>
                        <p className='mt-5 fw-b fs-1'>{item.name}</p>
                        {
                          // 이미지 파일인 경우 미리보기 표시
                          ['jpg', 'jpeg', 'png', 'svg', 'gif'].includes(item.name.substring(item.name.lastIndexOf(".") + 1)) && <img alt={item.name} style={{width: '100%'}} src={`${process.env.REACT_APP_API_URL_BASE}/${item.file_url}`}/>
                        }
                      </Grid.Column>
                      <Grid.Column width={7}>
                        <div className={'is-flex'} style={{justifyContent: 'flex-end', flexWrap: 'wrap'}}>
                          <Button color={'teal'} className='mt-3' onClick={
                            async () => {
                              axios({
                                url: `${process.env.REACT_APP_API_URL_BASE}/${item.file_url}`,
                                method: 'GET',
                                responseType: 'blob', // important
                              }).then((response) => {
                                const url = window.URL.createObjectURL(new Blob([response.data]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', item.name);
                                document.body.appendChild(link);
                                link.click();
                              });
                            }
                          }><Icon name={'download'}/> download</Button>
                          {
                            this.state.user.uuid === item.user.uuid && <Button negative className='ml-5 mt-3' onClick={() => this.deleteFile(item.uuid)}><Icon name={'trash'}/> delete</Button>
                          }
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                )
              }
            </>
            :
            <Loader inline={'centered'} active/>
        }
      </div>
    )
  }
}


export default withCookies(Upload)