import React, { Component } from "react";
import { Button, Form, Icon, Input } from "semantic-ui-react";
import axios from "axios";

export default class ChangePasswordPage extends Component{
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      new_password: '',
      re_new_password: '',
    }
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  changePassword = async () => {
    if (this.state.new_password !== this.state.re_new_password) {
      alert("패스워드가 일치하지 않습니다."); // TODO: 한/영 구분
      return;
    }
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/user/change-user-password`, {
        email: this.state.email,
        new_password: this.state.new_password,
      })
      alert('패스워드를 변경했습니다!');
    } catch (e) {
      alert(e.response.data.ko); // TODO: 한/영 구분 ex. e.response.data.en
    }
  }

  render() {
    return (
      <div>
        <h1>비밀번호 변경</h1>
        <Form className={'mt-8 mb-8'}>
          <Form.Field required>
            <label>이메일</label>
            <Input name='email' icon='user' iconPosition='left' className='mv-5' fluid 
                  placeholder={'비밀번호를 변경할 이메일을 입력해주세요.'}
                  value={this.state.email} onChange={this.handleChange}/>
          </Form.Field>
          <Form.Field required>
            <label>암호</label>
            <Input type={'password'} name='new_password' icon='lock' iconPosition='left' className='mv-5' fluid
                  placeholder={'새 비밀번호를 입력해주세요.'}
                  value={this.state.new_password} onChange={this.handleChange}/>
          </Form.Field>
          <Form.Field required>
            <label>암호 재확인</label>
            <Input type={'password'} name='re_new_password' icon='lock' iconPosition='left' className='mv-5' fluid
                   placeholder={'한번 더 비밀번호를 입력해주세요.'}
                   value={this.state.re_new_password} onChange={this.handleChange}/>
          </Form.Field>
        </Form>
        <Button primary className='mb-8' onClick={this.changePassword}><Icon name={'check'}/> 확인</Button>
      </div>
    );
  }
}