import React, {Component} from "react";
import { FormattedMessage } from "react-intl";
import {Button, Card, Icon, Image, Modal} from "semantic-ui-react";

export default class IntroModal extends Component {
  state = {isOpen: false}

  render() {
    return <Modal
      onClose={() => this.setState({isOpen: false})}
      onOpen={() => this.setState({isOpen: true})}
      open={this.state.isOpen}
      dimmer={'inverted'}
      trigger={
        <Card>
          <Image src={this.props.img} wrapped ui={false} className={'pd-3'}/>
          <Card.Content>
            <Card.Header>{this.props.title}</Card.Header>
          </Card.Content>
        </Card>
      }
    >
      <Modal.Content>
       <h1>{this.props.title}</h1>
        <p>{this.props.content}</p>
        <Button className='mt-7' fluid basic color='blue' onClick={() => this.setState({isOpen: false})}><Icon name={'check'}/> <FormattedMessage id='confirm_button'/></Button>
      </Modal.Content>
    </Modal>
  }
}