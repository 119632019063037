import React, {Component} from "react";
import {Button, Dropdown, Form, Grid, Icon, Label, Popup, Radio, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import OpenAI from "openai";


class GPT extends Component {
  state = {
    age: null,
    content: null,
    x_axis: null,
    y_axis: null,
    method: null,
    condition: null,
    result: null,
    difference: null,
    disappoint: null,
    plan: null,
    gpt_running: false,
    report: null,
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  gpt = async () => {
    this.setState({gpt_running: true});
    // 추후 gpt 요청을 서버에서 보내서 받아오도록 수정 필요함.
    const openai = new OpenAI({apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true});
    const completion = await openai.chat.completions.create({
      messages: [{ 
        role: "system", 
        content: 
`#명령문
- 당신은 한국에서 학교에 다니고 있는 과학영재 학생입니다.
- 당신의 아주 독창적인 과학실험 프로젝트를 진행하고 있습니다.
- 오늘 당신이 실험한 결과를 그래프로 그려서 첨부파일로 첨부했으니 그것을 참고해서 작성하세요.
- (실험조건)은 구체적이고 명시적으로 간단명료하게 작성해서 글을 읽는 사람이 쉽게 따라할 수 있도록 쓰세요.

#제약조건
- 너무 어려운 용어를 사용하지 말라.
- 글의 수준은 (나이)에 맞는 어휘, 문장길이로 글을 작성한다.
- 보고서 전체 목차를 만들어서 쓰는 것이 아니라 보고서 중 한 문단 정도만 작성한다.
- "습니다."와 같은 경어체를 쓰는 것이 아니라 "했다."라는 식의 끝맺음을 쓴다.
- 첨부한 그래프에서 발견되는 실험결과의 특이점을 작성한다.

#입력문
- 나이: ${this.state.age}세
- 오늘 측정한 내용: ${this.state.content}
- 그래프에서 X축의 의미: ${this.state.x_axis}
- 그래프에서 Y축의 의미: ${this.state.y_axis}
- 어떤 방법으로 측정을 했는가: ${this.state.method}
- 실험조건: ${this.state.condition}
- 실험결과: ${this.state.result}
- 과거 실험과 달라진 점: ${this.state.difference}
- 아쉬운 점: ${this.state.disappoint}
- 향후실험계획: ${this.state.plan}

#출력문`
      }],
      model: "gpt-3.5-turbo",
      // model: "gpt-4",
    });
    this.setState({
      gpt_running: false,
      report: completion.choices[0]['message']['content'],
    });
  }

  render() {
    
    return (
      <div className='mt-7'>
        <h1><FormattedMessage id='gpt'/></h1>
        <p><FormattedMessage id='gpt_content'/></p>

        <Segment fluid className='pd-1 mt-6'>
          <Label size='large' color='blue' ribbon>
            <FormattedMessage id='basic_info_title'/>
          </Label>
          <Form className='mt-5'>
            <Form.Input type={'number'} fluid label={<FormattedMessage id='age'/>} placeholder='나이를 입력해주세요.' value={this.state.age} name='age' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='content'/>} placeholder='측정한 내용을 입력해주세요.' value={this.state.content} name='content' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='x_axis'/>} placeholder='x축이 나타내는 것을 입력해주세요.' value={this.state.x_axis} name='x_axis' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='y_axis'/>} placeholder='y축이 나타내는 것을 입력해주세요.' value={this.state.y_axis} name='y_axis' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='method'/>} placeholder='측정 방법을 입력해주세요.' value={this.state.method} name='method' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='condition'/>} placeholder='실험 조건을 입력해주세요.' value={this.state.condition} name='condition' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='result'/>} placeholder='실험 결과를 입력해주세요.' value={this.state.result} name='result' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='difference'/>} placeholder='과거 실험과 달라진 점을 입력해주세요.' value={this.state.difference} name='difference' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='disappoint'/>} placeholder='아쉬운 점을 입력해주세요.' value={this.state.disappoint} name='disappoint' onChange={this.handleChange}/>
            <Form.Input fluid label={<FormattedMessage id='plan'/>} placeholder='향후 실험 계획을 입력해주세요.' value={this.state.plan} name='plan' onChange={this.handleChange}/>
          </Form>
        </Segment>
        <Button color='teal' className='mv-6' onClick={() => this.gpt()}><Icon name={'play'}/> <FormattedMessage id='create_report_button'/></Button>
        {
          this.state.gpt_running? 
            <div>잠시만 기다려주세요.</div>
          :
            this.state.report? 
            <Segment fluid className='pd-1 mt-6'>
              {this.state.report}
            </Segment>
            :
            <></>
        }
      </div>
    )
  }
}

export default GPT