import React, {Component} from "react";
import {Container, Dropdown, Image, Menu} from "semantic-ui-react";
import AppLogo from "../flowlab.png";
import {useCookies} from "react-cookie";
import { FormattedMessage } from "react-intl";

const options = [
  {
    key: 'english',
    text: '🇺🇸ㅤEnglish',
    value: 'en',
    content: '🇺🇸ㅤEnglish',
  },
  {
    key: 'korean',
    text: '🇰🇷ㅤ한국어',
    value: 'ko',
    content: '🇰🇷ㅤ한국어',
  },
]

function Footer() {
  const [cookies, setCookie, removeCookie] = useCookies(['language']);

  return (
    <div className='footer' style={{backgroundColor: "rgb(241, 238, 235)"}}>
      <Container className='pv-7'>
        <Image size='tiny' src={AppLogo}/>
        <p className='fw-b mt-6' style={{lineHeight: '2em'}}>
          <FormattedMessage id='flow_studio'/> ﹒ <FormattedMessage id='address'/> ﹒ <FormattedMessage id='ceo'/> ﹒ <FormattedMessage id='business_number'/> ﹒ <FormattedMessage id='mail_order_sales_registration_number'/> ﹒ <FormattedMessage id='call_number'/> ﹒ flowstudio.science@outlook.kr
        </p>
        <p className='color-gray'>
          ⓒ 2021. FLOW STUDIO All rights reserved.
        </p>

        {/* 언어 설정 */}
        <div className='is-flex mt-8' style={{justifyContent: 'flex-end'}}>
          <Menu.Item>
            <Dropdown
              upward
              inline pointing
              options={options}
              value={cookies.language ?? 'ko'}
              defaultValue={cookies.language ?? 'ko'}
              onChange={(event, data) => {
                setCookie('language', data.value, {
                  path: "/"
                })
              }}
            />
          </Menu.Item>
        </div>

        <p className='color-gray has-text-right mt-8'>
          Developed by <a href={'https://poapper.com'}>PoApper, Inc.</a>
          <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a
            href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
        </p>
      </Container>
    </div>
  );
}
export default Footer