import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Form, Icon, Input, Modal, Select} from "semantic-ui-react";


let options = [{},]
const typelist = [
    {
        key: "i", text: "서론", value: "INSTRUCTION"
    }, {
        key: "e", text: "실험", value: "EXPERIMENT"
    },{
        key: "r", text: "결과", value: "RESULT"
    },{
        key: "c", text: "결론", value: "CONCLUSION"
    }]

const languagelist = [
    {
        key: "k", text: "한글", value: "ko"
    },
    {
        key: "e", text: "English", value: "en"
    }
]

function AddQuestion(props){
    const [open, setOpen] = useState(false);
    const [template, setTemplate, removeTemplate] = useState();
    const [language, setLanguage, removeLanguage] = useState();
    const [question, setQuestion, removeQuestion] = useState();
    const [type, setType, removeType] = useState();
    const [isLoaded, setIsLoaded, removeSetLoaded] = useState(false);

    const handleLanguage =(e, data) =>{
        setLanguage(data.value);
    }

    const handleType = (e, data) =>{
        setType(data.value);
    }

    const handleQuestion = (e) =>{
        setQuestion(e.target.value);
    }

    const sendData =async ()=>{
        await axios.post(`${process.env.REACT_APP_API_URL}/question`, {
            language: language,
            question: question,
            type: type,
            template_uuid: props.exp.uuid
        },{withCredentials:true})
        setOpen(false)
        props.reload()
    }

    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button fluid basic className='pd-1'><Icon name='add'/> New Question</Button>}
    >
        <Modal.Header>질문 추가</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <p>해당 템플릿을 활용한 보고서 작성에 필요한 질문을 추가합니다.</p>
                <Form className={'mt-6'}>
                    <Form.Field fluid label="실험 언어" control={Select} options={languagelist} placeholder="언어" onChange={handleLanguage} className={'pb-5'}/>
                    <Form.Field fluid label="실험 과정" control={Select} options={typelist} placeholder="실험 전개 수준" onChange={handleType} className={'pb-5'}/>
                    <Form.Field fluid label="질문 내용" control={Input} value={question} placeholder="질문 내용" onChange={handleQuestion} className={'pb-5'}/>
                </Form>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
                Cancel
            </Button>
            <Button
                content="Add"
                onClick={sendData}
                positive
            />
        </Modal.Actions>

    </Modal>
}

export default AddQuestion