import { useEffect, useState } from "react";
import axios from "axios";
import { Table, Input } from "semantic-ui-react";
import moment from "moment";
import Profile from "../../utils/profile";

function ManageUser() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user`, { withCredentials: true }).then((res) => {
      setUsers(res.data);
    });
  }, []);

  const handlePaymentAmountChange = (email, value) => {
    setUsers(users.map(user => 
      user.email === email ? { ...user, payment_amount: value } : user
    ));
  };

  const handlePaymentAmountBlur = (email, value) => {
    axios.put(`${process.env.REACT_APP_API_URL}/user/update-payment-amount`, { email, payment_amount: value }, { withCredentials: true })
      .then((res) => {
        console.log('Payment amount updated successfully');
      })
      .catch((err) => {
        console.error('Error updating payment amount:', err);
      });
  };

  return (
    <div>
      <h2>User Management</h2>

      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Date Joined</Table.HeaderCell>
            <Table.HeaderCell>Subscription Renewal Date</Table.HeaderCell>
            <Table.HeaderCell>Payment Amount</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map((user) => (
            <Table.Row key={user.id}>
              <Table.Cell className={'has-item-vcentered'}>
                <Profile size={36} src={user.profile_url} />
                <p className='ml-5'>{user.name}</p>
              </Table.Cell>
              <Table.Cell>{user.email}</Table.Cell>
              <Table.Cell>{user.type}</Table.Cell>
              <Table.Cell>{moment(user.created_at).format('YYYY. MM. DD. HH:mm')}</Table.Cell>
              <Table.Cell>{moment(user.subscription_until).format('YYYY. MM. DD. HH:mm')}</Table.Cell>
              <Table.Cell>
                <Input
                  type="number"
                  value={user.payment_amount}
                  onChange={(e) => handlePaymentAmountChange(user.email, e.target.value)}
                  onBlur={(e) => handlePaymentAmountBlur(user.email, e.target.value)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
}

export default ManageUser;
