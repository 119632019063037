import {Loader, Tab} from "semantic-ui-react";
import Main from "./main";
import Upload from "./upload";
import Analysis from "./analysis";
import Report from "./report";
// import GPT from "./gpt";
import {useParams} from "react-router-dom";
import axios from "axios";
import {useEffect, useState} from "react";


function Exptab() {
  const title = useParams().expUuid;
  const [exp, setExp] = useState();

  useEffect(async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/experiment/${title}`, {withCredentials: true});
    setExp(res.data);
    // console.log(res.data);
    }, [])

  return <>
    {
      exp ? <>
          <Tab menu={{secondary: true, pointing: true}} panes={
            [
              {
                menuItem: 'Main',
                render: () => <Main exp={exp}/>
              },
              {
                menuItem: 'Upload',
                render: () => <Upload exp={exp}/>
              },
              {
                menuItem: 'Analysis',
                render: () => <Analysis exp={exp}/>
              },
              {
                menuItem: 'Report',
                render: () => <Report exp={exp}/>
              },
              // {
              //   menuItem: 'GPT',
              //   render: () => <GPT exp={exp}/>
              // },
            ]
          }/>
        </> :
        <Loader active inline={'centered'}/>
    }
  </>
}

export default Exptab;