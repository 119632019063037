import {useEffect, useState} from "react";
import axios from "axios";
import {Button, Form, Icon, Input, Modal, Select} from "semantic-ui-react";


let options = [{},]
const typelist = [
    {
        key: "i", text: "서론", value: "INSTRUCTION"
    }, {
        key: "e", text: "실험", value: "EXPERIMENT"
    },{
        key: "r", text: "결과", value: "RESULT"
    },{
        key: "c", text: "결론", value: "CONCLUSION"
    }]

const languagelist = [
    {
        key: "k", text: "한글", value: "ko"
    },
    {
        key: "e", text: "English", value: "en"
    }
]

function UpdateQuestion(props){
    const [open, setOpen] = useState(false);
    const [language, setLanguage, removeLanguage] = useState();
    const [question, setQuestion, removeQuestion] = useState();
    const [type, setType, removeType] = useState();
    const [isLoaded, setIsLoaded, removeSetLoaded] = useState(false);

    const handleLanguage =(e, data) =>{
        setLanguage(data.value);
    }

    const handleType = (e, data) =>{
        setType(data.value);
    }

    const handleQuestion = (e, data) =>{
        setQuestion(data.value);
    }

    useEffect(
        async ()=>{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/question/${props.uuid}`,
                {
                    withCredentials: true
                })
            const temp = res.data
            setLanguage(temp.language);
            setType(temp.type);
            setQuestion(temp.question)

            setIsLoaded(true);
        }, []
    )

    const sendData =async ()=>{
        await axios.put(`${process.env.REACT_APP_API_URL}/question/${props.uuid}`, {
            language: language,
            question: question,
            type: type,
        },{withCredentials:true})
        setOpen(false)
        props.reload()
    }

    return <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={<Button className={'blue'}>Update</Button>}
    >
        <Modal.Header>질문 수정</Modal.Header>
        <Modal.Content>
            <Modal.Description>
                <p>질문 내용을 수정합니다. 이미 작성된 보고서의 질문은 변경되지 않습니다.</p>
                <Form className={'mt-6'}>
                    <Form.Field fluid label="실험 언어" control={Select} value={language} options={languagelist} placeholder="언어" onChange={handleLanguage} className={'pb-5'}/>
                    <Form.Field fluid label="실험 과정" control={Select} value={type} options={typelist} placeholder="실험 전개 수준" onChange={handleType} className={'pb-5'}/>
                    <Form.Field fluid label="질문 내용" control={Input} value={question} placeholder="질문 내용" onChange={handleQuestion} className={'pb-5'}/>
                </Form>
            </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
            <Button color='black' onClick={() => setOpen(false)}>
                cancel
            </Button>
            <Button
                content="Update"
                onClick={sendData}
                primary
            />
        </Modal.Actions>

    </Modal>
}

export default UpdateQuestion