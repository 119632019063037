import {Tab} from "semantic-ui-react";
import ManageUser from "./manage-user";
import Template from "./templates";
import JoinIn from "../account/joinin";
import ChangePasswordPage from "./change-user-password";
import GPT from '../experiments/gpt'
import axios from "axios";

function Admin() {

  axios.get(`${process.env.REACT_APP_API_URL}/is-admin`, {withCredentials: true})
    .catch((e)=>{
      alert('잘못된 접근입니다.');
      window.location.href='/';
    })

  return <>
    <h1>Admin Console</h1>
    <Tab menu={{secondary: true, pointing: true}} panes={
      [
        {
          menuItem: 'templates',
          render: () => <Template/>
        },
        {
          menuItem: 'manage users',
          render: () => <ManageUser/>
        },
        {
          menuItem: 'create account',
          render: () => <JoinIn/>
        },
        {
          menuItem: 'change user password',
          render: () => <ChangePasswordPage/>
        },
        { // 현재 gpt 서비스는 admin만 사용할 수 있게 했습니다.
          menuItem: 'gpt',
          render: () => <GPT/>
        }
      ]
    }/>
  </>
}

export default Admin;