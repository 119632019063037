import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { Form, Button, Icon, Modal, Image } from 'semantic-ui-react';
import Profile from "../../utils/profile";
import {useIntl} from 'react-intl';
import { loadTossPayments } from '@tosspayments/payment-sdk'
// const clientKey = 'test_ck_D5GePWvyJnrK0W0k6q8gLzN97Eoq'
const clientKey = 'live_ck_5OWRapdA8dQdAX6Wvd2P3o1zEqZK'

const ProfilePage = ({ cookies }) => {
  const intl = useIntl();
  const history = useHistory();
  const [state, setState] = useState({
    user: null,
    name: '',
    subscriptionUntil: '0000-00-00',
    today: '',
    file: null,
    isOpen: false,
    isPaymentSuccessful: false, // 결제 성공 플래그 추가
    isAdmin: false, // 버튼 활성화 상태 추가
  });

  useEffect(() => {
    if (cookies.get('token')) {
      const token = cookies.get('token');
      const decoded = jwt_decode(token).user;
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0');
      const yyyy = today.getFullYear();

      // 이메일 확인 로직 추가
      const isAdmin = decoded.email === 'flowstudio.science@outlook.kr';

      setState({
        user: decoded,
        name: decoded.name,
        subscriptionUntil: decoded.subscription_until ?? '0000-00-00',
        today: `${yyyy}-${mm}-${dd}`,
        isAdmin, // 관리자 여부 설정
      });
    } else {
      history.push('/login');
    }
  }, [cookies, history]);

  const handleAdminButtonClick = () => {
    if (state.isAdmin) {
      history.push('/admin'); // 관리자 이메일인 경우 /admin-page로 이동
    } else {
      alert('잘못된 접근입니다.'); // 관리자 이메일이 아닌 경우 접근 제한
    }
  };

  const handleChange = (e, { name, value }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileClick = () => {
    document.getElementById('hiddenFileInput').click();
  };

  const handleFile = (e) => {
    setState(prevState => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const updateProfile = async () => {
    const formData = new FormData();
    formData.append('name', state.name);
    formData.append('uploaded_file', state.file);

    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/user/own`, formData, { withCredentials: true });
      alert("정보를 변경했습니다! 다시 로그인 해주세요.");
      await axios.get(`${process.env.REACT_APP_API_URL}/user/logout`);
      cookies.set("Authentication", '', { path: "/" });
      window.location.href = '/account/login';
    } catch (e) {
      console.log(e);
      alert('문제가 발생했습니다.');
    }
  };

  const changePassword = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/user/reset-password?email=${state.user.email}`);
      alert("비밀번호 재설정 링크를 메일로 보냈습니다. 메일함을 확인해주세요.");
    } catch (e) {
      console.log(e);
      alert('문제가 발생했습니다.');
    }
  };

  const billingButtonClicked = () => {
    loadTossPayments(clientKey).then(tossPayments => {
      // ------ 카드 등록창 호출 ------
      tossPayments
        .requestBillingAuth('카드', {
          // 결제수단 파라미터 (자동결제는 카드만 지원합니다.)
          // 결제 정보 파라미터
          // 더 많은 결제 정보 파라미터는 결제창 Javascript SDK에서 확인하세요.
          // https://docs.tosspayments.com/reference/js-sdk#requestbillingauth카드-결제-정보
          customerKey: state.user.uuid, // 구매자 ID로 상점에서 만들어야 합니다. 빌링키와 매핑됩니다. 자세한 파라미터 설명은 결제 정보 파라미터 설명을 참고하세요.
          successUrl: 'https://grafting.co.kr/payment/success', // 카드 등록에 성공하면 이동하는 페이지(직접 만들어주세요)
          failUrl: 'https://grafting.co.kr/payment/fail', // 카드 등록에 실패하면 이동하는 페이지(직접 만들어주세요)
        })
        // ------ 결제창을 띄울 수 없는 에러 처리 ------
        // 메서드 실행에 실패해서 reject 된 에러를 처리하는 블록입니다.
        // 결제창에서 발생할 수 있는 에러를 확인하세요.
        // https://docs.tosspayments.com/reference/error-codes#결제창공통-sdk-에러
        .catch(function (error) {
          if (error.code === 'USER_CANCEL') {
            // 결제 고객이 결제창을 닫았을 때 에러 처리
            alert('결제가 취소되었습니다.')
          }
        })
    })
  };

  const refundService = () => {
    const paymentKey = state.user.payment_key; // 사용자 정보에서 paymentKey를 가져옵니다.
    const subscriptionAmount = state.user.payment_amount;
    const subscriptionUntil = new Date(state.user.subscriptionUntil); // subscription_until 날짜를 가져옵니다.
    const currentDate = new Date(); // 현재 날짜

    // 남은 일 수 계산
    const reaminedDays = Math.floor((subscriptionUntil - currentDate) / (1000 * 60 * 60 * 24));
    // 남은 주 수 계산
    const reaminedWeeks = Math.ceil(reaminedDays / 7);
    // 환불 금액 계산 (7일마다 구독료의 1/4씩 차감)
    const modifiedAmount = subscriptionAmount * reaminedWeeks * 0.25;
  
    axios.post(`https://api.tosspayments.com/v1/payments/${paymentKey}/cancel`, {
      cancelAmount: modifiedAmount,
      cancelReason: '고객이 취소를 원함',
    }, {
      headers: {
        'Authorization': 'Basic bGl2ZV9za19uUlFvT2FQejhMeDd4ZFlEZHhSUDh5NDdCTXc2Og==',
        'Content-Type': 'application/json'
      }
    })
    .then(response => {
      alert('환불이 완료되었습니다.');
      window.location.href = 'https://grafting.co.kr';
      axios.put(`${process.env.REACT_APP_API_URL}/user/reset-subscription-date`, {
        email: state.user.email,
      }).catch((e) => {
        alert(e);
      });
    })
    .catch(error => {
      console.error('환불 중 오류가 발생했습니다:', error);
      alert('환불 중 오류가 발생했습니다.');
    });
  };

  if (!state.user) return null;

  return (
    <div>
      <h1>{intl.formatMessage({ id: 'profile' })}</h1>
      <div></div>
      <div className='has-item-centered'>
        <Profile size={128} src={state.user.profile_url} />
      </div>
      <Form>
        <Form.Input
          id='hiddenFileInput'
          label={intl.formatMessage({ id: 'profile_pic' })}
          onChange={handleFile}
          style={{display: 'none'}}
          type='file'
          accept='image/*'
        />
        <Button onClick={handleFileClick}>
          <Icon name='upload' />{intl.formatMessage({ id: 'choose_image' })}
        </Button>
        {state.file && <span>{state.file.name}</span>}
        <Form.Input
          label={intl.formatMessage({ id: 'user_name' })}
          onChange={handleChange}
          name='name'
          value={state.name}
        />
        <Form.Input
          readOnly
          label={intl.formatMessage({ id: 'email' })}
          onChange={handleChange}
          value={state.user.email}
        />
      </Form>
      <Button primary className='mv-8 mr-5' onClick={updateProfile}>
        <Icon name='user' />{intl.formatMessage({ id: 'change_info' })}
      </Button>
      {state.isAdmin && (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button style={{ margin: '0 10px' }} onClick={handleAdminButtonClick}>
            {intl.formatMessage({ id: 'admin_button' })}
          </Button>
        </div>
      )}
      <Form>
        {state.subscriptionUntil < state.today ? (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Input label={intl.formatMessage({ id: 'consulting_service' })} value={intl.formatMessage({ id: 'not_in_use' })} style={{ width: '128px' }} />
              <Modal
                onClose={() => setState(prevState => ({ ...prevState, isOpen: false }))}
                onOpen={() => setState(prevState => ({ ...prevState, isOpen: true }))}
                open={state.isOpen}
                dimmer='inverted'
                trigger={<Button style={{ marginLeft: 'auto' }}>{intl.formatMessage({ id: 'use_service' })}</Button>}
              >
                <Modal.Content>
                  <Image src='/img/service/1.png' />
                  <Image src='/img/service/2.png' />
                  <Image src='/img/service/3.png' />
                  <Image src='/img/service/4.png' />
                  <Image src='/img/service/5.png' />
                  <Image src='/img/service/6.png' />
                  <Image src='/img/service/7.png' />
                  <div style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '18px' }}>{intl.formatMessage({ id: 'price' })}</div>
                  <div style={{ color: 'gray', textAlign: 'right'}}>{intl.formatMessage({ id: 'duration' })}</div>
                  <div style={{ display: 'flex' }}>
                    <a target="_blank" href="/refund-policy" style={{ color: 'gray', textDecoration: 'underline', cursor: 'pointer', marginLeft: 'auto' }}>{intl.formatMessage({ id: 'refund_policy' })}</a>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Button className='mt-7' fluid basic onClick={() => setState(prevState => ({ ...prevState, isOpen: false }))}>{intl.formatMessage({ id: 'try_later' })}</Button>
                    <Button className='mt-7' fluid basic color='blue' onClick={() => {
                      billingButtonClicked();
                      setState(prevState => ({ ...prevState, isPaymentSuccessful: true })); // 결제 성공 시 플래그 업데이트
                    }}>{intl.formatMessage({ id: 'apply_monthly_pay' })}</Button>
                  </div>
                </Modal.Content>
              </Modal>
            </div>
          </>
        ) : (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Form.Input label={intl.formatMessage({ id: 'consulting_service' })} value={`${intl.formatMessage({ id: 'in_use' })} , ~${state.subscriptionUntil.slice(0, 10)}`} />
              <Modal
                onClose={() => setState(prevState => ({ ...prevState, isOpen: false }))}
                onOpen={() => setState(prevState => ({ ...prevState, isOpen: true }))}
                open={state.isOpen}
                dimmer='inverted'
                trigger={<Button style={{ marginLeft: 'auto' }}>{intl.formatMessage({ id: 'more' })}</Button>}
              >
                <Modal.Content>
                  정기상담 서비스 설명
                  <Button className='mt-7' fluid basic color='blue' onClick={() => setState(prevState => ({ ...prevState, isOpen: false }))}>닫기</Button>
                  <Image src='/img/service/1.png' />
                  <Image src='/img/service/2.png' />
                  <Image src='/img/service/3.png' />
                  <Image src='/img/service/4.png' />
                  <Image src='/img/service/5.png' />
                  <Image src='/img/service/6.png' />
                  <Image src='/img/service/7.png' />
                </Modal.Content>
              </Modal>
              <Button style={{ margin: '0 10px' }} onClick={refundService}>{intl.formatMessage({ id: 'refund_service' })}</Button>
              {state.isPaymentSuccessful && (
                <Button style={{ marginLeft: 'auto' }} onClick={refundService}>{intl.formatMessage({ id: 'refund_service' })}</Button>)}
            </div>
          </>
        )}
      </Form>
      {/* TODO: api 호출해서 메일 보내기 */}
      {/* 아래는 비밀번호 변경 버튼입니다. 정책상 사용자가 사용할 수 없어 주석처리했습니다. */}
      {/* <Button className='mv-8' onClick={changePassword}><Icon name='lock' /> 암호 변경</Button> */}
    </div>
  );
};

ProfilePage.propTypes = {
  cookies: PropTypes.instanceOf(Cookies).isRequired,
};

export default withCookies(ProfilePage);