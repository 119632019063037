import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
// import ChangePasswordPage from "./change-password.page";
import { Container } from "semantic-ui-react";
import Login from "./login";
// import JoinIn from "./joinin";
import {useCookies} from "react-cookie";
import ProfilePage from "./profile.page";

function AccountModule(){
    const [cookies, setCookie, removeCookie] = useCookies(['language']);
    const [login, setLogin, removeLogin] = useCookies(['Authentication']);
    return (
        <Container className={'pv-8'}>
            <Switch>
                {/* 현재 회원 가입 및 비밀번호 변경 불가 */}
                {/* <Route exact path={"/account/change-password"} component={ChangePasswordPage} /> */}
                <Route exact path={"/account/login"} component={Login} />
                {/* <Route exact path={"/account/joinin"} component={JoinIn} /> */}
              <Route exact path={"/account/profile"} component={ProfilePage} />
            </Switch>
        </Container>
    );
}

export default AccountModule;
