import React from 'react'
import {Button, Container, Dropdown, Icon, Image, Menu} from 'semantic-ui-react'
import MediaQuery from 'react-responsive'
import AppLogo from '../flowlab.png';
import {useCookies} from "react-cookie";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import Profile from "../utils/profile";
import jwt_decode from "jwt-decode";

const options = [
  {
    key: 'english',
    text: '🇺🇸ㅤEnglish',
    value: 'en',
    content: '🇺🇸ㅤEnglish',
  },
  {
    key: 'korean',
    text: '🇰🇷ㅤ한국어',
    value: 'ko',
    content: '🇰🇷ㅤ한국어',
  },
]


function Navbar(props) {
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [login, setLogin, removeLogin] = useCookies(['token'])

  const logOut = async() =>{
    await axios.get(`${process.env.REACT_APP_API_URL}/user/logout`);
    await removeLogin('Authentication', {path: "/"})
    await removeLogin('token', {path: "/"})
    if(cookies.language ==='ko'){
      alert("로그아웃 되었습니다.")
    }
    else{
      alert("You are now logged out.")
    }
    window.location.href = "/"
  }

  const user = login.token ? jwt_decode(login.token).user : null

  return (
      <div style={{
        borderBottom: '1px solid rgba(0,0,0,.1)',
        background: '#fff',
        position: 'fixed',
        width: '100%',
        top: 0,
        zIndex: 10
      }} className='pv-1'>
        <Container>
          <Menu secondary>
            <MediaQuery maxWidth={991}>
              <Menu.Item onClick={() => props.openSidebar(true)}>
                <Icon name='sidebar' className='mg-0'/>
              </Menu.Item>
            </MediaQuery>

            {/* 로고 */}
            <Menu.Item className='pd-0 mv-4 nav-logo mr-8 ph-2' onClick={() => window.location.href = '/'}>
              <div className='is-flex has-item-vcentered'>
                <img alt='logo' height={'50'} className='mr-5' src={AppLogo}/>
                <p className='fw-b fs-2' style={{color: 'rgb(169, 154, 128)'}}>Grafting</p>
              </div>
            </Menu.Item>

            <MediaQuery minWidth={992}>
              {/* 메뉴 */}
              {
                user !== null &&
                  <a href = "/experiment/" className={'has-item-vcentered'} style={{color : "black", fontWeight: "bold"}}>
                  <FormattedMessage id='my_experiment'/>
                  </a>

              }

              {/* 언어 설정 */}
              <Menu.Item>
                <Dropdown
                    inline pointing
                    options={options}
                    value={cookies.language ?? 'ko'}
                    defaultValue={cookies.language ?? 'ko'}
                    onChange={(event, data) => {
                      setCookie('language', data.value, {
                        path: "/"
                      })
                    }}
                />
              </Menu.Item>

              {/* 바로 가기 */}
              <Menu.Item
                  onClick={() => window.location.href = 'https://youtube.com/channel/UCiEUYq6Vx5y1jRDXXzOwCMw'}
                  position='right'
              >
                <Icon className='mg-0' name='youtube'/>
              </Menu.Item>
              <Menu.Item
                  onClick={() => window.location.href = 'https://www.pinterest.co.kr/flowerystudio'}
              >
                <Icon className='mg-0' name='pinterest'/>
              </Menu.Item>
              <Menu.Item
                  onClick={() => window.location.href = 'https://www.instagram.com/flowstudio.grafting'}
              >
                <Icon className='mg-0' name='instagram'/>
              </Menu.Item>
              <Menu.Item
                  onClick={() => window.location.href = 'https://www.facebook.com/flowstudio.grafting'}
              >
                <Icon className='mg-0' name='facebook'/>
              </Menu.Item>
              <Menu.Item
                  onClick={() => window.location.href = 'https://www.twitter.com/flowerygrafting'}
              >
                <Icon className='mg-0' name='twitter'/>
              </Menu.Item>
            </MediaQuery>

            {/* 로그인 & 회원가입 */}
            {
              user === null ?
                <MediaQuery minWidth={992}>
                  <Menu.Item position='right'>
                      <Button basic onClick={() => { window.location.href = "/account/login"
                      }} className='mr-5'><Icon name='sign in'/><FormattedMessage id='sign_in'/></Button>
                      {/* <Button basic onClick={() => { window.location.href = "/account/joinin"
                      }}><Icon name='signup'/><FormattedMessage id='sign_up'/></Button> */}
                    </Menu.Item>
                  </MediaQuery>
                :
                <Menu.Item position='right'>
                  <Dropdown
                    floating
                    pointing='top right'
                    icon={null}
                    trigger={<Profile size={36} src={user ? user.profile_url : null}/>}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => {window.location.href ="/account/profile"}}><Icon name='user'/><FormattedMessage id='profile'/></Dropdown.Item>
                      <Dropdown.Item onClick={logOut}><Icon name='sign-out'/><FormattedMessage id='sign_out'/></Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
            }
          </Menu>
        </Container>
      </div>
  )
}

export default Navbar