import {Button, Form, Header, Icon, Input, Modal, Select, TextArea} from "semantic-ui-react";
import {useEffect, useState} from "react";
import axios from "axios";
import {useCookies} from "react-cookie";
import { FormattedMessage } from "react-intl";


    let options = [{},]

function AddExperiment(props){
    const [open, setOpen] = useState(false);
    const [title, setTitle, removeTitle] = useState();
    const [explain, setExplain, removeExplain] = useState();
    const [template, setTemplate, removeTemplate] = useState();
    const randStr = Math.random().toString(36).substr(2,8);
    const [login, setLogin, removeLogin] = useCookies(['Authentication']);



    const handleTitle = (e) =>{
        setTitle(e.target.value);
    }

    const handleTemplate = (e, data) =>{
        setTemplate(data.value);
    }

    const handleExplain = (e) => {
        setExplain(e.target.value);
    }

    useEffect(
        async ()=>{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/template`,
                {
                    withCredentials: true
                })
            const temp = res.data
            let list = [];
            await temp.map((item,index)=>{
                list[index] = {key: index, text: item.title, value: item.uuid};
            })
            options = list
            console.log(options)
        }, []
    )

    const uploadData = async () => {
       await axios.post(`${process.env.REACT_APP_API_URL}/experiment`, {
           title: title,
           description: explain,
           experiment_id: randStr,
           template_uuid: template,
       },{withCredentials: true})
        setOpen(false)
        setExplain("")
        setTemplate("")
        setTitle("")
        props.reload()
    }



    return (
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button basic className='pd-1 mr-5 mb-5'><Icon name='add'/> <FormattedMessage id='create_experiment_button'/></Button>}
        >
            <Modal.Header><FormattedMessage id='create_experiment_title'/></Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <Header><FormattedMessage id='enter_info_title'/></Header>
                    <p><FormattedMessage id='enter_info_content'/></p>
                    <Form className={'mt-6'}>
                        <Form.Field fluid label={<FormattedMessage id='experiment_template'/>} control={Select} options={options} placeholder="실험 템플릿을 선택해주세요." onChange={handleTemplate}/>
                        <Form.Field fluid label={<FormattedMessage id='experiment_title'/>} control={Input} value={title} onChange={handleTitle} placeholder="실험 제목을 입력해주세요." className={'pv-5'}/>
                        <Form.Field fluid label={<FormattedMessage id='experiment_explanation'/>} control={TextArea} value={explain} placeholder="실험 설명을 간단히 작성해주세요." onChange={handleExplain} className={'pv-5'} style={{minHeight:200}}/>
                        <p className='mb-1 has-item-centered'>{<FormattedMessage id='experiment_code'/>}: {randStr}</p>
                    </Form>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button color='black' onClick={() => setOpen(false)}>
                    <FormattedMessage id='cancel_button'/>
                </Button>
                <Button
                    content={<FormattedMessage id='add_button'/>}
                    onClick={uploadData}
                    positive
                />
            </Modal.Actions>
        </Modal>
    )
}

export default AddExperiment;