import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import {useCookies} from "react-cookie";
import Admin from "./admin";
import TemplateTab from "./templateTab";


function AdminModule(){
    const [cookies, setCookie, removeCookie] = useCookies(['language']);
    const [login, setLogin, removeLogin] = useCookies(['Authentication']);

    return (
        <Container className={'pv-8'}>
            <Switch>
                <Route exact path={"/admin/"} component={Admin} />
                <Route path={"/admin/template/:tempUuid"} component={TemplateTab} />
            </Switch>
        </Container>
    );
}

export default AdminModule;
