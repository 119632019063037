function RefundPolicy(){
    return(
        <>
            <div style={{padding: '64px 0'}}>
                <div style={{fontWeight: 'bold', fontSize: '24px', textAlign: 'center', marginBottom: '64px'}}>플로우 스튜디오 환불정책</div>
                <div style={{display: 'flex'}}>
                    <div style={{margin: 'auto', padding: '8px'}}>
                        플로우스튜디오에서 구매한 일부 항목은 아래에 설명된 환불 정책에 따라 환불이 가능할 수 있습니다.<br/><br/>
                        친구나 가족 구성원이 내 계정을 사용하여 실수로 구매한 경우 고객센터에서 환불을 요청하세요.<br/><br/>
                        카드나 다른 결제 수단에 본인 또는 아는 사람이 결제하지 않은 구매 내역이 표시된다면 거래일로부터 120일 이내에 승인되지 않은 청구를 신고합니다.<br/><br/>
                        환불 요청이 승인되었다면 환불에 소요되는 기간을 확인해 보세요.<br/><br/>
                        동일한 거래에 대해 여러 번 요청해도 환불 절차가 더 빠르게 처리되지 않습니다. 환불 결정은 영업일 기준 1~4일이 소요될 수 있습니다.<br/><br/>
                    </div>
                </div>
            </div>
        </>
      );
}
  
export default RefundPolicy;