import React, {Component} from "react";
import {Scatter} from "react-chartjs-2";
import {Button, Dropdown, Form, Grid, Icon, Label, Popup, Radio, Segment} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import Latex from 'react-latex';

const chartAreaBorder = {
  id: 'chartAreaBorder',
  beforeDraw(chart, args, options) {
    const {ctx, chartArea: {left, top, width, height}} = chart;
    ctx.save();
    ctx.strokeStyle = options.borderColor;
    ctx.lineWidth = options.borderWidth;
    ctx.setLineDash(options.borderDash || []);
    ctx.lineDashOffset = options.borderDashOffset;
    ctx.strokeRect(left, top, width, height);
    ctx.restore();
  }
};

class Analysis extends Component {
  state = {
    xAxisName: '', xAxisMin: 0, xAxisMax: 1, xAxisStep: 0, xAxisSubStep: 2,
    yAxisName: '', yAxisMin: 0, yAxisMax: 1, yAxisStep: 0, yAxisSubStep: 5,
    dataColor: 'rgb(255, 99, 132)',
    dataColor2: 'rgb(0, 0, 0)',
    selectedFittingFunc: 0,
    fittingA: 0,
    fittingB: 0,
    fittingC: 0,
    fittingD: 0,
    fittingE: 0,
    submits: [],
    fileName: '',
    rawData: [],
    data: [],
    xAxisRawDataIdx: 0,
    yAxisRawDataIdx: 0,
    isLoaded: false,
    addData: false,
    fileName2: '',
    rawData2: [],
    data2: [],
  }

  async componentDidMount() {
    const submits = await axios.get(`${process.env.REACT_APP_API_URL}/submit/experiment/${this.props.exp.uuid}`, {withCredentials: true});
    this.setState({
      submits: submits.data.filter(e => e.name.substring(e.name.lastIndexOf(".") + 1) === 'txt').map((e) => { return { key: e.uuid, value: e.file_url, text: e.name } }),
      isLoaded: true,
    });
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  handleRawDataChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    }, () => this.setDateToGraph());
  }

  setDateToGraph() {
    const formattedData = [];
    for (let i in this.state.rawData[this.state.xAxisRawDataIdx].data) {
      formattedData.push({x: this.state.rawData[this.state.xAxisRawDataIdx].data[i], y: this.state.rawData[this.state.yAxisRawDataIdx].data[i]})
    }
    if (this.state.rawData2.length > 0) { // 데이터2
      const formattedData2 = [];
      for (let i in this.state.rawData2[this.state.xAxisRawDataIdx].data) {
        formattedData2.push({x: this.state.rawData2[this.state.xAxisRawDataIdx].data[i], y: this.state.rawData2[this.state.yAxisRawDataIdx].data[i]})
      }
      this.setState({
        data2: formattedData2
      })
    }

    const minX = Math.min(...this.state.rawData[this.state.xAxisRawDataIdx].data);
    const maxX = Math.max(...this.state.rawData[this.state.xAxisRawDataIdx].data);
    const minY = Math.min(...this.state.rawData[this.state.yAxisRawDataIdx].data);
    const maxY = Math.max(...this.state.rawData[this.state.yAxisRawDataIdx].data);
    const rangeX = maxX - minX;
    const rangeY = maxY - minY;

    this.setState({
      data: formattedData,
      xAxisName: this.state.rawData[this.state.xAxisRawDataIdx].label,
      yAxisName: this.state.rawData[this.state.yAxisRawDataIdx].label,
      xAxisStep : 10 ** Math.floor(Math.log10(rangeX)),
      yAxisStep : 10 ** Math.floor(Math.log10(rangeY)),
    }, () => {
      this.setState({
        xAxisMin: this.state.xAxisStep > 0 ? Math.round(minX/this.state.xAxisStep) * this.state.xAxisStep : minX,
        xAxisMax: this.state.xAxisStep > 0 ? Math.round(maxX/this.state.xAxisStep) * this.state.xAxisStep : maxX,
        yAxisMin: this.state.yAxisStep > 0 ? Math.round(minY/this.state.yAxisStep) * this.state.yAxisStep : minY,
        yAxisMax: this.state.yAxisStep > 0 ? Math.round(maxY/this.state.yAxisStep) * this.state.yAxisStep : maxY,
      })
    })
  }

  handleChangeNumber = (e, data) => {
    this.setState({
      [data.name]: data.value === '' ? null : parseFloat(data.value)
    })
  }

  generateFittingData() {
    const res = [];
    const divisor = 50;
    const minX = this.state.xAxisMin;
    const incrementX = (this.state.xAxisMax - this.state.xAxisMin)/divisor;
    for (let i = 0; i < divisor; i++) {
      let t = minX + i * incrementX;
      if (t == 0) { t = 0.00001; }
      switch (this.state.selectedFittingFunc) {
        case 0:
          res.push({ x: t, y: this.state.fittingA * t * t + this.state.fittingB * t + this.state.fittingC }); // y = ax^2 + bx + c
          break;
        case 1:
          res.push({ x: t, y: this.state.fittingA * (t ** this.state.fittingB) + this.state.fittingC }); // y = ax^b + c
          break;
        case 2:
          res.push({ x: t, y: this.state.fittingA * (this.state.fittingB ** (this.state.fittingC * t + this.state.fittingD)) + this.state.fittingE }); // y = ab^(cx+d) + e
          break;
        case 3:
          res.push({ x: t, y: 1 / ( Math.sqrt(2 * Math.PI) * this.state.fittingA ) * ( Math.E ** (-1 / (2 * this.state.fittingA ** 2) * ((t - this.state.fittingB) ** 2)) ) }); // 정규분포
          break;
        case 4:
          res.push({ x: t, y: this.state.fittingC + this.state.fittingA * ( Math.E ** (this.state.fittingB * t) ) }); // dT/dt = k(T-T0)
          break;
        case 5:
          res.push({ x: t, y: this.state.fittingC / (1 + ( this.state.fittingA * this.state.fittingB ** t )) }); // logistic
          break;
      }
    }
    return res;
  }

  downloadImg() {
    let canvas = window.document.getElementById("canvas");
    let dataURL = canvas.toDataURL('image/png');
    dataURL = dataURL.replace(/^data:image\/[^;]*/, 'data:application/octet-stream');
    dataURL = dataURL.replace(/^data:application\/octet-stream/, 'data:application/octet-stream;headers=Content-Disposition%3A%20attachment%3B%20filename=Canvas.png');

    let aTag = document.createElement('a');
    aTag.download = `${this.state.title}.png`;
    aTag.href = dataURL;
    aTag.click();
  };

  handleDataFile = async (e, data) => {
    await axios({
      url: `${process.env.REACT_APP_API_URL_BASE}/${data.value}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then(async (response) => {
      const text = await new Blob([response.data]).text();

      let dataObj = []

      const line = text.split('\n').filter((val) => val != '');
      const label = line[0].split(' ').filter((val) => val != '\r')
      for (let i = 0; i < label.length; i++) {
        let tmp = []
        for (let j = 1; j < line.length; j++) {
          tmp.push(line[j].split(' ')[i]);
        }
        dataObj.push({label: label[i], data: tmp});
      }

      this.setState({
        ['rawData' + data.name]: dataObj,
        ['fileName' + data.name]: data.value.split('/')[1].split('.')[0], // 앞의 'submit/' 날리고 '.txt' 날리기 위함.
      }, () => this.setDateToGraph());
    });
  }


  render() {
    let xAxisSubStep = this.state.xAxisSubStep;
    let yAxisSubStep = this.state.yAxisSubStep;
    let options = {
      responsive: true,
      scales: {
        x: {
          type: 'linear',
          // max: this.state.xAxisMax, // 왜인지는 모르겠으나 max 설정을 안해야 잘 나온다.
          min: this.state.xAxisMin,
          grace: this.state.xAxisStep, // 축에 min max 설정을 넘는 여분 설정.
          ticks: {
            padding: 15,
            color: 'black',
            font: function(context) {
              return { size: context.chart.width/32 };
            },
            callback: function(val, index) {
              return index % xAxisSubStep === 0 ? this.getLabelForValue(val) : '';
            },
            stepSize: this.state.xAxisStep/this.state.xAxisSubStep/2, // 나누기 2를 해줘야 정상 작동. 아마 버그인 듯함.
          },
          title: {
            display: true,
            text: this.state.xAxisName,
            color: 'black',
            font: function(context) {
              return { size: context.chart.width/32 };
            },
          },
          grid: {
            tickLength: -8,
            tickWidth: function(val) {
              return val.index % xAxisSubStep === 0 ? 2 : 1;
            },
            color: function(val) {
              return val.index % xAxisSubStep === 0 ? 'rgba(0, 0, 0, 0.1)' : null;
            },
            // tickColor: 'Black',
            tickColor: function(val) {
              return val.index % xAxisSubStep === 0 ? 'red' : 'black';
            },
            display: true,
          },
          offset: true,
        },
        y: {
          type: 'linear',
          // max: this.state.yAxisMax,
          min: this.state.yAxisMin,
          grace: this.state.yAxisStep,
          ticks: {
            padding: 15,
            color: 'black',
            font: function(context) {
              return { size: context.chart.width/32 };
            },
            callback: function(val, index) {
              return index % yAxisSubStep === 0 ? this.getLabelForValue(val) : '';
            },
            maxTicksLimit : 100,
            stepSize: this.state.yAxisStep/this.state.yAxisSubStep/2, 
          },
          title: {
            display: true,
            text: this.state.yAxisName,
            color: 'black',
            font: function(context) {
              return { size: context.chart.width/32 };
            },
          },
          grid: {
            tickLength: -8,
            tickWidth: function(val) {
              return val.index % yAxisSubStep === 0 ? 2 : 1;
            },
            color: function(val) {
              return val.index % yAxisSubStep === 0 ? 'rgba(0, 0, 0, 0.1)' : null;
            },
            // tickColor: 'Black',            
            tickColor: function(val) {
              return val.index % yAxisSubStep === 0 ? 'red' : 'black';
            },
            display: true,
          },
          offset: true,
        },
      },
      plugins: {
        chartAreaBorder: {
          borderColor: 'black',
          borderWidth: 2,
        },
        legend: {
          display: this.state.addData,
          // position: 'bottom',
          labels: {
            filter: function(legendItem) {
              return legendItem.text != 'fitting';
            },
          }
        },
      }
    };

    let data = { datasets: [] };

    data['datasets'].push(
      {
        label: this.state.fileName,
        data: this.state.data, // 실험 데이터
        backgroundColor: this.state.dataColor,
        pointRadius: function(context) {
          return context.chart.width/90;
        },
      }
    );

    if (this.state.addData) { // '데이터 추가' 버튼을 누른 경우
      data['datasets'].push(
        {
          label: this.state.fileName2,
          data: this.state.data2, // 실험 데이터 2
          backgroundColor: this.state.dataColor2,
          pointRadius: function(context) {
            return context.chart.width/90;
          },
        }
      )
    }

    data['datasets'].push(
      {
        label: 'fitting',
        type: 'line',
        data: this.generateFittingData(), // 피팅 데이터
        borderDash: [6, 4],
        backgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        tension: 0.1,
      }
    );

    return (
      <div className='mt-7'>
        <h1><FormattedMessage id='analysis'/></h1>
        <p><FormattedMessage id='analysis_content'/></p>

        <Segment fluid className='mt-6'>
          <Label size='large' color='green' ribbon>
            <FormattedMessage id='choose_data_title'/>
          </Label>
          <div>
            <Dropdown
              className='mv-5'
              placeholder='Select data file'
              name=''
              fluid
              search
              selection
              options={this.state.submits.filter((val)=> val.text != this.state.fileName2 + '.txt')} // 같은 파일 선택 방지
              onChange={this.handleDataFile}
            />
            <Grid columns={2} stackable className='ph-1'>
              <Grid.Column>
                <p className={'fw-b'}><FormattedMessage id='select_x_axis'/></p>
                {
                  this.state.rawData.map((e, idx) =>
                    <div className='mb-3'>
                      <Radio
                        label={e.label}
                        name='xAxisRawDataIdx'
                        value={idx}
                        checked={this.state.xAxisRawDataIdx === idx}
                        onChange={this.handleRawDataChange}
                      />
                    </div>
                  )
                }
              </Grid.Column>
              <Grid.Column>
                <p className={'fw-b'}><FormattedMessage id='select_y_axis'/></p>
                {
                  this.state.rawData.map((e, idx) =>
                    <div className='mb-3'>
                      <Radio
                        label={e.label}
                        name='yAxisRawDataIdx'
                        value={idx}
                        checked={this.state.yAxisRawDataIdx === idx}
                        onChange={this.handleRawDataChange}
                      />
                    </div>
                  )
                }
              </Grid.Column>

            </Grid>
            {
              this.state.addData? 
              <>
                <Dropdown
                  className='mv-5'
                  placeholder='Select data file2'
                  name='2'
                  fluid
                  search
                  selection
                  options={this.state.submits.filter((val)=> val.text != this.state.fileName + '.txt')} // 같은 파일 선택 방지
                  onChange={this.handleDataFile}
                />
                <button onClick={() => this.setState({addData:false})}><FormattedMessage id='remove_data'/></button>
              </>
              :
              <button onClick={() => this.setState({addData:true})}><FormattedMessage id='add_data'/></button>
            }
          </div>
        </Segment>
        <Segment fluid className='pd-1 mt-6'>
          <Label size='large' color='yellow' ribbon>
            <FormattedMessage id='fitting_function_title'/>
          </Label>

          <p className='mt-5 color-gray'><FormattedMessage id='fitting_function_content'/></p>
          <div className='mb-5 mt-7'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={0}
              checked={this.state.selectedFittingFunc === 0}
              onChange={this.handleChange}
            />
            <Latex>{'$$y=\\textcolor{red}{a}x^2 + \\textcolor{red}{b}x +\\textcolor{red}{c}$$'}</Latex>
          </div>

          <div className='mb-5'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={1}
              checked={this.state.selectedFittingFunc === 1}
              onChange={this.handleChange}
            />
            <Latex>{'$$y=\\textcolor{red}{a}x^\\textcolor{red}{b} + \\textcolor{red}{c}$$'}</Latex>

            <Popup
              content={<p><b><FormattedMessage id='polynomial_title'/>:</b> <FormattedMessage id='polynomial_content'/></p>}
              on='click'
              pinned
              trigger={<Button className='ml-4' circular icon={'question'} size={'mini'} />}
            />
          </div>

          <div className='mb-5'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={2}
              checked={this.state.selectedFittingFunc === 2}
              onChange={this.handleChange}
            />
            <Latex>{'$$y = \\textcolor{red}{a} \\cdot \\textcolor{red}{b}^\{\\textcolor{red}{d}x + \\textcolor{red}{d}\} + \\textcolor{red}{e}$$'}</Latex>

            <Popup
              content={<p><b><FormattedMessage id='transcendental_title'/>:</b> <FormattedMessage id='transcendental_content'/></p>}
              on='click'
              pinned
              trigger={<Button className='ml-4' circular icon={'question'} size={'mini'} />}
            />
          </div>

          <div className='mb-5'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={3}
              checked={this.state.selectedFittingFunc === 3}
              onChange={this.handleChange}
            />
            <Latex>{'$$y = \\frac{1}{\\textcolor{red}{a}\\sqrt{2\\pi}}\exp({-\\frac{1}{2}(\\frac{x-\\textcolor{red}{b}}{\\textcolor{red}{a}})^2})$$'}</Latex>
          </div>

          <div className='mb-5'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={4}
              checked={this.state.selectedFittingFunc === 4}
              onChange={this.handleChange}
            />
            <Latex>{'$$y = \\textcolor{red}{a} \\cdot \exp({\\textcolor{red}{b}x}) + \\textcolor{red}{c}$$'}</Latex>

            <Popup
              content={<p><b><FormattedMessage id='newton_title'/>:</b> <FormattedMessage id='newton_content'/></p>}
              on='click'
              pinned
              trigger={<Button className='ml-4' circular icon={'question'} size={'mini'} />}
            />
          </div>

          <div className='mb-5'>
            <Radio
              className='mr-5'
              name='selectedFittingFunc'
              value={5}
              checked={this.state.selectedFittingFunc === 5}
              onChange={this.handleChange}
            />
            <Latex>{'$$y = \\frac{\\textcolor{red}{c}}{1+\\textcolor{red}{a}\\textcolor{red}{b}^x} (\\textcolor{red}{c}>0, \\textcolor{red}{a}>0, 1>\\textcolor{red}{b}>0)$$'}</Latex>

            <Popup
              content={<p><b><FormattedMessage id='logistic_title'/>:</b> <FormattedMessage id='logistic_content'/></p>}
              on='click'
              pinned
              trigger={<Button className='ml-4' circular icon={'question'} size={'mini'} />}
            />
          </div>

          <Form className='mt-7'>
            <Form.Group widths={'equal'}>
              <Form.Input type={'number'} fluid label='a' placeholder='a' value={this.state.fittingA} name='fittingA' onChange={this.handleChangeNumber}/>
              <Form.Input type={'number'} fluid label='b' placeholder='b' value={this.state.fittingB} name='fittingB' onChange={this.handleChangeNumber}/>
              <Form.Input type={'number'} fluid label='c' placeholder='c' value={this.state.fittingC} name='fittingC' onChange={this.handleChangeNumber}/>
              <Form.Input type={'number'} fluid label='d' placeholder='a' value={this.state.fittingD} name='fittingD' onChange={this.handleChangeNumber}/>
              <Form.Input type={'number'} fluid label='e' placeholder='e' value={this.state.fittingE} name='fittingE' onChange={this.handleChangeNumber}/>
            </Form.Group>
          </Form>
        </Segment>

        <Segment fluid className='pd-1 mt-6'>
          <Label size='large' color='orange' ribbon>
            <FormattedMessage id='graph_title'/>
          </Label>
          <div style={{maxWidth: '800px', margin: '0 auto'}}> {/* 그래프 사이즈 조절을 위한 div */}
            <Scatter id='canvas' data={data} options={options} width={200} height={150} plugins={[chartAreaBorder]}/>
          </div>
        </Segment>
        <Button color='teal' className='mv-6' onClick={() => this.downloadImg(this)}><Icon name={'download'}/> <FormattedMessage id='graph_download_button'/></Button>
      </div>
    )
  }
}

export default Analysis