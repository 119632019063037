import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "semantic-ui-react";
import Lookup from "./lookup";
import Upload from "./upload";
import Main from "./main";
import Analysis from "./analysis";
import Report from "./report";
import {useCookies} from "react-cookie";
import Exptab from "./exptab";


function ExperimentModule(){
    const [cookies, setCookie, removeCookie] = useCookies(['language']);
    const [login, setLogin, removeLogin] = useCookies(['Authentication']);

    return (
        <Container className={'pv-8'}>
            <Switch>
                <Route exact path={"/experiment/"} component={Lookup} />
                {/*<Route path={"/experiment/:expName/main"} component={Main} />*/}
                {/*<Route path={"/experiment/:expName/analysis"} component={Analysis} />*/}
                {/*<Route path={"/experiment/:expName/report"} component={Report} />*/}
                {/*<Route path={"/experiment/:expName/upload"} component={Upload} />*/}
                <Route path={"/experiment/:expUuid"} component={Exptab} />
            </Switch>
        </Container>
    );
}

export default ExperimentModule;
