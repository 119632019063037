import {Component} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Divider, Message, Progress, Segment} from "semantic-ui-react";
import axios from "axios";
import AddQuestion from "./add-question";
import UpdateQuestion from "./update-question";


class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            QuestionList: [],
        }
    }

    loadData = async () => {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/question/template/${this.props.exp.uuid}`, {withCredentials: true})
        const temp = res.data
        this.setState({
            QuestionList: temp
        })
        console.log(temp)
    }

    componentDidMount() {
        this.loadData()
    }

    deleteData = async (UUID) =>{
        await axios.delete(`${process.env.REACT_APP_API_URL}/question/${UUID}`, {withCredentials: true})
        this.loadData()
    }


    render() {
        return (
            <div>
                <h2>Question List</h2>
                {this.state.QuestionList.map(data => (
                    <Segment className={'pointer'} >
                        <h4>진행수준: {data.type}</h4>
                        <p>{data.question}</p>
                        <Divider/>
                        {/*팀원: {data.users.map((name)=>(name + " "))}<br/><br/>*/}
                        <UpdateQuestion uuid={data.uuid} reload={this.loadData}/>
                        <Button className={'red'} onClick={() => this.deleteData(data.uuid)}>Delete</Button>
                        <p className={'has-text-right color-gray'}>언어: {data.language}</p>
                    </Segment>
                ))}
                <AddQuestion exp={this.props.exp} reload={this.loadData}/>
            </div>
        )
    }
}

export default Questions;