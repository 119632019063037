import Navbar from './components/navbar';
import {Sidebar} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import {Route, Switch} from "react-router-dom";
import Footer from "./components/footer";
import "./App.css";
import MainPage from "./pages/main/main.page";
import AccountModule from "./pages/account/account.module";
import {useCookies} from "react-cookie";
import {IntlProvider} from "react-intl";
import en from "./locale/en"
import ko from "./locale/ko"
import SidebarMenu from "./components/sidebar-menu";
import ExperimentModule from "./pages/experiments/experiment.Module";
import AdminModule from "./pages/admin/admin.module";
import RefundPolicy from './pages/policy/refund-policy';
import PaymentSuccess from './pages/account/payment-success';
import PaymentFail from './pages/account/payment-fail';

function App() {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [login, setLogin, removeLogin] = useCookies(['Authentication']);

  useEffect(() => {
    if (cookies.language === undefined) {
      setCookie('language', 'ko');
    }
    if (login.Authentication === undefined) {
      setLogin('Authentication', '',{path: '/'});
    }
  })

  return (
    <IntlProvider locale={cookies.language} messages={cookies.language === 'ko' ? ko : en}>
      <div className="App">
        <Navbar openSidebar={setSidebarVisible}/>
        <Sidebar
          animation='overlay'
          onHide={() => setSidebarVisible(false)}
          vertical
          visible={isSidebarVisible}
          style={{justifyContent: 'center', display: 'flex', height: '100%', background: '#fff', zIndex: 5}}
        >
          <div style={{marginTop: '100px'}}>
            <SidebarMenu/>
          </div>
        </Sidebar>
        <Sidebar.Pushable>
          <Sidebar.Pusher>
            <div style={{marginTop: '75px'}}>
              <Switch>
                {/* TODO: 여기에 페이지 Route 추가하면 됩니당! */}
                <Route exact path={"/"} component={MainPage}/>
                <Route path={"/account"} component={AccountModule}/>
                <Route path={"/experiment"} component={ExperimentModule}/>
                <Route path={"/admin"} component={AdminModule}/>
                <Route path={"/refund-policy"} component={RefundPolicy}/>
                <Route path={"/payment/success"} component={PaymentSuccess}/>
                <Route path={"/payment/fail"} component={PaymentFail}/>
              </Switch>
            </div>
            <Footer/>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    </IntlProvider>
  );
}

export default App;
