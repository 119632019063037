import {useState} from "react";
import {Button, Form, Icon, Input, Modal, Select} from "semantic-ui-react";
import axios from "axios";

const options = [
  {key: 'k', text: "한글", value: "ko"},
  {key: 'e', text: "English", value: "en"}
]

function AddTemplate(props) {
  const [title, setTitle, removeTitle] = useState();
  const [lang, setLang, removeLang] = useState();
  const [open, setOpen] = useState(false);

  const handleTitle = (e) => {
    setTitle(e.target.value);
  }

  const handleLang = (e, data) => {
    setLang(data.value);
  }

  const sendData = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/template`, {
      language: lang,
      title: title,
    }, {withCredentials: true});
    setTitle("");
    setLang("");
    setOpen(false);
    props.reload();
  }

  return <Modal
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    open={open}
    trigger={<Button fluid basic className='pd-1'><Icon name='add'/> New Template</Button>}
  >
    <Modal.Header>새로운 템플릿 생성</Modal.Header>
    <Modal.Content>
      <Form>
        <Form.Field fluid label="언어" control={Select} options={options} placeholder="언어를 선택해주세요."
                    onChange={handleLang} className={'pb-5'}/>
        <Form.Field fluid label="템플릿 제목" control={Input} value={title} placeholder="템플릿 제목을 입력해주세요."
                    onChange={handleTitle} className={'pb-5'}/>
      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button color='black' onClick={() => setOpen(false)}>
        취소
      </Button>
      <Button
        content="생성"
        onClick={sendData}
        positive
      />
    </Modal.Actions>
  </Modal>
}

export default AddTemplate