import React, {Component, useState} from "react";
import {Button, Divider, Dropdown, Grid, Icon, Image, Menu, Modal} from "semantic-ui-react";
import {useCookies} from "react-cookie";
import {FormattedMessage} from "react-intl";
import axios from "axios";
import jwt_decode from "jwt-decode";
import IntroModal2 from "../pages/main/intro-modal2";

const options = [
  {
    key: 'english',
    text: '🇺🇸ㅤEnglish',
    value: 'en',
    content: '🇺🇸ㅤEnglish',
  },
  {
    key: 'korean',
    text: '🇰🇷ㅤ한국어',
    value: 'ko',
    content: '🇰🇷ㅤ한국어',
  },
]

function SidebarMenu() {
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [login, setLogin, removeLogin] = useCookies(['Authentication']);
  const [open, setOpen] = useState(false);

    const logOut = async() =>{
        await axios.get(`${process.env.REACT_APP_API_URL}/user/logout`);
        await removeLogin('Authentication', {path: "/"})
        await removeLogin('token', {path: "/"})

      if(cookies.language ==='ko'){
            alert("로그아웃 되었습니다.")
        }
        else{
            alert("You are now logged out.")
        }
        window.location.href = "/"
    }

  const user = login.token ? jwt_decode(login.token).user : null

  return (
          <Menu secondary vertical>

            <p className='fw-b'>Grafting</p>
            <Divider/>
              {
                user !== null &&
                <>
                  <Menu.Item
                    onClick={() => window.location.href = '/experiment/'}
                    position='right'
                  >
                    <FormattedMessage id='my_experiment'/>
                  </Menu.Item>
                </>
              }
              <Modal
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                open={open}
                dimmer={'inverted'}
                trigger={
                  <Menu.Item>
                    <FormattedMessage id='arduino_equipment_button'/>
                  </Menu.Item>}
              >
                <Modal.Content>
                  <Grid columns={4} doubling className='has-item-centered'>
                    <Grid.Column>
                      <IntroModal2
                        title={<FormattedMessage id='inclined_plane_title'/>}
                        content={<FormattedMessage id='inclined_plane_content'/>}
                        img={'/img/main-card/slope.png'}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <IntroModal2
                        title={<FormattedMessage id='inquery_title'/>}
                        content={<FormattedMessage id='inquery_content'/>}
                        img={'/img/main-card/interview.png'}
                      />
                    </Grid.Column>
                  </Grid>
                </Modal.Content>
                <Modal.Actions className='has-item-centered'>
                  <Button color={'blue'} basic onClick={() => setOpen(false)}><Icon name='check'/> <FormattedMessage id='confirm_button'/></Button>
                  <Button color={'pink'} basic onClick={() => window.location.href='http://minishop.gmarket.co.kr/arduinomarket'}><Icon name='cart'/> <FormattedMessage id='store_button'/></Button>
                </Modal.Actions>
              </Modal>
              <div className='mb-8'/>


              <p className='fw-b'><FormattedMessage id='language'/></p>
              <Divider/>

              {/* 언어 설정 */}
              <Menu.Item
                  position='right'
              >
                  <Dropdown
                      inline pointing
                      fluid
                      options={options}
                      defaultValue={cookies.language ?? 'ko'}
                      onChange={(event, data) => {
                          setCookie('language', data.value, {
                              path: "/"
                          })
                      }}
                  />
              </Menu.Item>

              <p className='fw-b mt-8'><FormattedMessage id='contents'/></p>
              <Divider/>

              {/* 바로 가기 */}
              <Menu.Item
                onClick={() => window.location.href = 'https://youtube.com/channel/UCiEUYq6Vx5y1jRDXXzOwCMw'}
                position='right'
              >
                <Icon className='mg-0' name='youtube'/> YouTube
              </Menu.Item>
              <Menu.Item
                onClick={() => window.location.href = '#'}
              >
                  <Icon className='mg-0' name='pinterest'/> Pinterest
              </Menu.Item>
              <Menu.Item
                onClick={() => window.location.href = 'https://www.instagram.com/flowstudio.grafting'}
              >
                  <Icon className='mg-0' name='instagram'/> Instagram
              </Menu.Item>
              <Menu.Item
                onClick={() => window.location.href = 'https://www.facebook.com/flowstudio.grafting'}
              >
                  <Icon className='mg-0' name='facebook'/> Facebook
              </Menu.Item>
              <Menu.Item
                onClick={() => window.location.href = 'https://www.twitter.com/flowerygrafting'}
              >
                  <Icon className='mg-0' name='twitter'/> Twitter
              </Menu.Item>

              {
                user === null ?
                      <Menu.Item position='right'>
                          <Button fluid basic onClick={() => {window.location.href = "/account/login"
                          }} className='mb-5 mt-8'><Icon name='sign in'/><FormattedMessage id='sign_in'/></Button>
                          {/* 현재 회원가입 막아놓았음. */}
                          {/* <Button fluid basic onClick={() => {window.location.href = "/account/joinin"
                          }} className='mb-8'><Icon name='signup'/><FormattedMessage id='sign_up'/></Button> */}
                      </Menu.Item>
                  :
                      <Menu.Item position='right'>
                          <Button fluid basic onClick={logOut
                          } className='mb-5 mt-8'><Icon name='sign-out'/><FormattedMessage id='sign_out'/></Button>
                      </Menu.Item>
              }
          </Menu>
      );

}

export default SidebarMenu