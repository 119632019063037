import React, {Component} from "react";
import "./main.page.css";
import {Button, Container, Grid, Icon, Modal} from "semantic-ui-react";
import {FormattedMessage} from "react-intl";
import IntroModal from "./intro-modal";
import AppLogo from "../../flowlab.png";
import IntroModal2 from "./intro-modal2";

class MainPage extends Component {
  state = {isOpen: false}

  render() {
    return(
      <>
        {/* 메인 이미지 및 버튼 */}
        <div className="main-img-box">
          <div className='kenburns-top' style={{width: '100%', height: '100%'}}>
            <img className="main-img" src="https://cdn.pixabay.com/photo/2017/10/17/14/10/financial-2860753_1280.jpg"/>
          </div>
          <div className="ui container" style={{position: "absolute"}}>
            <div style={{padding: "2rem"}}>
              <h1 className='fs-4 fw-b mb-8 color-black'><FormattedMessage id='title'/></h1>

              <div className='mt-5'>
                <Modal
                  onClose={() => this.setState({isOpen: false})}
                  onOpen={() => this.setState({isOpen: true})}
                  open={this.state.isOpen}
                  dimmer={'inverted'}
                  trigger={<Button color='teal'><FormattedMessage id='arduino_equipment_button'/> <Icon name='chevron right'/></Button>}
                >
                  <Modal.Content>
                    <Grid columns={4} doubling className='has-item-centered'>
                      <Grid.Column>
                        <IntroModal2
                          title={<FormattedMessage id='inclined_plane_title'/>}
                          content={<FormattedMessage id='inclined_plane_content'/>}
                          img={'/img/main-card/slope.png'}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <IntroModal2
                          title={<FormattedMessage id='inquery_title'/>}
                          content={<FormattedMessage id='inquery_content'/>}
                          img={'/img/main-card/interview.png'}
                        />
                      </Grid.Column>
                    </Grid>
                  </Modal.Content>
                  <Modal.Actions className='has-item-centered'>
                    <Button color={'blue'} basic onClick={() => this.setState({isOpen: false})}><Icon name='check'/> <FormattedMessage id='confirm_button'/></Button>
                    <Button color={'pink'} basic onClick={() => window.location.href='http://minishop.gmarket.co.kr/arduinomarket'}><Icon name='cart'/> <FormattedMessage id='store_button'/></Button>
                  </Modal.Actions>
                </Modal>
              </div>
              <div className='mt-5'>
                <Button primary onClick={() => window.location.href='/experiment'}><FormattedMessage id='write_report_button'/> <Icon name='chevron right'/></Button>
              </div>
            </div>
          </div>
        </div>

        {/* 설명 카드 */}
        <div style={{backgroundColor: '#FCFAF9'}}>
          <Container className='pv-8'>
            <Grid columns={5} doubling>
              <Grid.Column>
                <IntroModal
                  title={'Science'}
                  content={<FormattedMessage id='science_content'/>}
                  img={'/img/main-card/science.svg'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal
                  title={'Arduino'}
                  content={<FormattedMessage id='arduino_content'/>}
                  img={'/img/main-card/arduino.svg'}
                >
                </IntroModal>
              </Grid.Column>
              <Grid.Column>
                <IntroModal
                  title={'Data Gathering'}
                  content={<FormattedMessage id='data_gathering_content'/>}
                  img={'/img/main-card/gathering.svg'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal
                  title={'Graph'}
                  content={<FormattedMessage id='graph_content'/>}
                  img={'/img/main-card/graph.svg'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal
                  title={'Fitting'}
                  content={<FormattedMessage id='fitting_content'/>}
                  img={'/img/main-card/fitting.svg'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal
                  title={'Analysis'}
                  content={<FormattedMessage id='analysis_content_main'/>}
                  img={'/img/main-card/analysis.svg'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal2
                  title={<FormattedMessage id='institution_title'/>}
                  content={<FormattedMessage id='institution_content'/>}
                  img={'/img/main-card/office-building.png'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal2
                  title={<FormattedMessage id='inclined_plane_title'/>}
                  content={<FormattedMessage id='inclined_plane_content'/>}
                  img={'/img/main-card/slope.png'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal2
                  title={<FormattedMessage id='flow_studio_title'/>}
                  content={<FormattedMessage id='flow_studio_content'/>}
                  img={'/img/main-card/diagram.png'}
                />
              </Grid.Column>
              <Grid.Column>
                <IntroModal2
                  title={<FormattedMessage id='inquery_title'/>}
                  content={<FormattedMessage id='inquery_content'/>}
                  img={'/img/main-card/interview.png'}
                />
              </Grid.Column>
            </Grid>
          </Container>

        </div>
        <div style={{backgroundColor: '#FCFAF9'}}>
          <Container>
            <div className='pt-8 pb-10'>
              <img alt='logo' height={'100'} className='mr-5' src={AppLogo}/>
              <h1 className='fs-4 fw-b'><FormattedMessage id='grafting_title'/></h1>
              <p className='fs-1'>
                <FormattedMessage id='grafting_content'/>
              </p>
            </div>
          </Container>
        </div>
      </>
    )
  }
}

export default MainPage;