import React, {useEffect, Component} from "react";
import Profile from "../../utils/profile";
import {Button, Divider, Form, Icon, Modal, Segment, TextArea} from "semantic-ui-react";
import {instanceOf} from "prop-types";
import {Cookies, withCookies} from "react-cookie";
import jwt_decode from "jwt-decode";
import axios from "axios";
import moment from "moment";
import { FormattedMessage } from "react-intl";

class AnswerCard extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {isOpen: false, user: jwt_decode(cookies.get('token')).user, answer: this.props.answer.answer}
    console.log('prop:', this.props.answer.answer, '\t state:', this.state.answer);
  }

  handleChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    })
  }

  componentDidUpdate() {
    if(this.props.answer.answer != this.state.answer){
      this.setState({answer: this.props.answer.answer})
    }
  }

  updateAnswer = () => {
    axios.put(`${process.env.REACT_APP_API_URL}/report/${this.props.answer.uuid}`, {answer: this.state.answer}, {withCredentials: true})
      .then(res => {
        this.props.reload(this.props.answer.user_uuid)
        this.setState({
          isOpen: false
        })
      })
      .catch(e => {
        console.log(e);
        alert('수정에 실패했습니다.')
      });
  }

  render() {
    return <Segment key={this.props.key}>
      <div className='has-item-vcentered'>
        <Profile className={'mr-5'} src={this.props.answer.user.profile_url} size={36}/>
        <div>
          <p className={'fw-b mb-0'}>{this.props.answer.user.name}</p>
          <p className='fs-m1 color-gray has-text-right'>{moment(this.props.answer.created_at).format('YYYY.MM.DD. HH:mm')}</p>
        </div>
      </div>
      <h3>Q. {this.props.answer.question}</h3>
      <Divider/>
      <p style={{wordBreak: 'break-word'}}>{this.props.answer.answer}</p>
      {
        this.props.answer.file_url && <img alt='report' style={{width: '100%'}} className='mb-5'
                                src={`${process.env.REACT_APP_API_URL_BASE}/${this.props.answer.file_url}`}/>
      }
      {
        this.props.answer.user_uuid == this.state.user.uuid && <Modal
          open={this.state.isOpen}
          onOpen={() => this.setState({isOpen: true})}
          onClose={() => this.setState({isOpen: false})}
          trigger={<Button><Icon name={'edit'}/><FormattedMessage id='edit_button'/></Button>}
        >
          <Modal.Header>
            <FormattedMessage id='edit_title'/>
          </Modal.Header>
          <Modal.Content>
            <Form>
              <TextArea name='answer' style={{minHeight: 300}} value={this.state.answer} onChange={this.handleChange} placeholder={'보고서 내용을 입력하세요.'}/>
            </Form>
            {
              this.props.answer.file_url && <img alt='report' style={{width: '100%'}} className='mb-5'
                                      src={`${process.env.REACT_APP_API_URL_BASE}/${this.props.answer.file_url}`}/>
            }
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({isOpen: false})}><FormattedMessage id='cancel_button'/></Button>
            <Button primary onClick={this.updateAnswer}><FormattedMessage id='edit_button'/></Button>
          </Modal.Actions>
        </Modal>
      }
    </Segment>
  }
}

export default withCookies(AnswerCard)