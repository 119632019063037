import {Component} from "react";
import {Segment} from "semantic-ui-react";
import AddTemplate from "../admin/add-template";
import axios from "axios";

class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expList: [],
    }
  }

  loadData = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/template`, {withCredentials: true})
    const temp = res.data
    this.setState({
      expList: temp
    })
  }

  componentDidMount() {
    this.loadData()
  }

  render() {
    return (
      <div>
        <h2>Template List</h2>
        {this.state.expList.map(data => (
          <Segment className={'pointer'} onClick={() => window.location.href = `/admin/template/${data.uuid}`}>
            <p className='fs-1 fw-b'>{data.title}</p>
            <p className='color-gray has-text-right'>언어: {data.language}</p>
          </Segment>
        ))}
        <AddTemplate reload={this.loadData}/>
      </div>
    )
  }
}

export default Template;