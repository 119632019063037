import React, {useEffect, useState} from "react";
import {Grid, Icon, Progress, Button, Image, Form, TextArea, Segment, Divider, Input} from "semantic-ui-react";
import axios from 'axios';

import {saveAs} from "file-saver";
import {Document, Paragraph, Packer, HeadingLevel, ImageRun} from "docx";
import Profile from "../../utils/profile";
import {useCookies} from "react-cookie";
import jwt_decode from "jwt-decode";
import AnswerCard from "./answer-card";
import {FormattedMessage} from "react-intl";

const Report = (props) => {
  const [reportData, setReportData] = useState('');
  const [questions, setQuestions] = useState([]);
  const [questionIdx, setQuestionIdx] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [login, setLogin, removeLogin] = useCookies(['Authentication']);
  const [file, setFile, removeFile] = useState({inputFile: '', preview: null});
  const user = login.token ? jwt_decode(login.token).user : {uuid: null, profile_url: null}
  const [selectedUser, setSelectedUser] = useState(user.uuid);

  const nextQuestionIdx = () => {
    if (questionIdx >= 0) setQuestionIdx(questionIdx - 1);
  }

  const sendAnswer = async (question, answer, file_url) => {
    // 1000자 제한 두는 if else 문. 현재는 글자 수 상관 없이 업로드 가능.
    // if (answer.length >= 1000) {
    //   const formData = new FormData();
    //   formData.append('experiment_uuid', props.exp.uuid);
    //   formData.append('question', question);
    //   formData.append('answer', answer);
    //   formData.append('maximum_character', 1000);
    //   formData.append('uploaded_file', file.file);

    //   await axios.post(`${process.env.REACT_APP_API_URL}/report`, formData, {withCredentials: true}).then(() => {
    //     setReportData("");
    //     setFile({inputFile: '', file: null, preview: null});
    //     nextQuestionIdx();
    //   })
    // } else {
    //   alert("1000자 이상 입력해주세요.");
    // }
    const formData = new FormData();
    formData.append('experiment_uuid', props.exp.uuid);
    formData.append('question', question);
    formData.append('answer', answer);
    formData.append('maximum_character', 1000);
    formData.append('uploaded_file', file.file);

    await axios.post(`${process.env.REACT_APP_API_URL}/report`, formData, {withCredentials: true}).then(() => {
      setReportData("");
      setFile({inputFile: '', file: null, preview: null});
      nextQuestionIdx();
    })
  }

  const generateDocx = async () => {
    const ans = []

    for (let idx in answers) {
      ans.push(new Paragraph({
        text: answers[answers.length - idx - 1].answer,
      }))
      if (answers[answers.length - idx - 1].file_url) {
        const img = await axios({
          url: `${process.env.REACT_APP_API_URL_BASE}/${answers[answers.length - idx - 1].file_url}`,
          method: 'GET',
          responseType: 'arraybuffer',
        })
        ans.push(new Paragraph({
          children: [new ImageRun({
            data: Buffer.from(Buffer.from(img.data, 'binary').toString('base64'), "base64"),
            transformation: {
              width: 500,
              height: 500
            },
          })
          ]
        }))
      }
    }

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              text: props.exp.title + "\n\n",
              heading: HeadingLevel.HEADING_1,
            }),
            ...ans
          ]
        }]
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, "report.docx");
    })
  }

  // set questions and questionIdx
  useEffect(() => {
    async function questions() {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/question/template/${props.exp.template_uuid}`, {withCredentials: true});
      const data = res.data;
      await setQuestions(data.map(({question}) => question));
    }

    questions();
  }, [])

  useEffect(() => {
    async function answers() {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/report/experiment/${props.exp.uuid}?user=${selectedUser}`, {withCredentials: true})
      const data = res.data;
      await setAnswers(data);
    }

    answers();
  }, [questionIdx])

  useEffect(() => {
    setQuestionIdx(questions.length > 0 ? answers.length > 0 ? questions.length - answers.length - 1 : questions.length - 1 : -1);
  }, [answers])


  const getTeamReport = async (userUuid) => {
    setSelectedUser(userUuid)
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/report/experiment/${props.exp.uuid}?user=${userUuid}`, {withCredentials: true})
    const data = res.data;
    await setAnswers(data);
  }

  const handleFile = async (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile({
        inputFile: e.target.value, file: file, preview: reader.result
      });
    }
    reader.readAsDataURL(file);
  }

  return (
    <div className='mt-7'>
      <h1><FormattedMessage id='report'/></h1>
      <p><FormattedMessage id='report_content'/></p>
      <Grid stackable className='mt-6'>
        <Grid.Column width={4}>
          <p className={'fw-b'}><FormattedMessage id='experiment_completion_rate'/></p>
          <Progress className='mb-5' percent={parseInt(answers.length / questions.length * 100)} indicating progress='percent'/>
          <Button fluid color='teal' onClick={generateDocx}><Icon name={'download'}/> <FormattedMessage id='download_button'/></Button>
        </Grid.Column>
        <Grid.Column width={12}>
          <p className='color-gray'><FormattedMessage id='select_teammate_subtitle'/></p>
          <div className='is-flex mb-5' style={{flexWrap: 'wrap'}}>
            {
              props.exp.user.map(user => <div className='has-item-vcentered mr-5 mb-5 pointer'
                                              onClick={() => getTeamReport(user.uuid)}>
                <Profile src={user.profile_url} size={36}/>
                <p className='ml-5 mr-5 fw-b'>{user.name}</p>
              </div>)
            }
          </div>
          {
            selectedUser === user.uuid && <Segment>
              <h3 className="mb-0">
                {questionIdx >= 0 ? `Q. ${questions[questionIdx]}` : "There are no questions"}
              </h3>
              {
                questionIdx >= 0 && <>
                  <div className="is-row mt-7">
                    <Profile src={user.profile_url} size={36}/>
                    <Form className='ml-5' style={{flexGrow: 1}}>
                      <TextArea style={{minHeight: 80}} value={reportData} onChange={e => {
                        setReportData(e.target.value)
                      }} placeholder={'보고서 내용을 입력하세요.'}/>
                    </Form>
                  </div>
                  <div className="is-row mt-4" style={{marginLeft: "50px"}}>
                    <label htmlFor='imgInput'>
                      <div className='ui button ph-5'><Icon className='mr-0' name="picture"/></div>
                    </label>
                    <input id='imgInput' style={{width: '100%', display: 'none'}} type="file" accept={'image/*'}
                           onChange={handleFile} value={file.inputFile}/>
                    <div className="is-flex ml-6 mr-4" style={{flexGrow: 1, alignItems: "center"}}>
                      <div className="mh-1" style={{flexGrow: 1}}>
                        {reportData.length}
                        {/* <Progress progress='ratio' value={reportData.length} total="1000" indicating
                                  style={{marginBottom: 0}}/> */}
                      </div>
                    </div>
                    <Button onClick={() => {
                      sendAnswer(questions[questionIdx], reportData, "null");
                    }} primary><Icon name={'send'}/> upload</Button>

                  </div>
                  {
                    file.preview !== null &&
                    <img
                      className='mt-5'
                      src={file.preview}
                      style={{width: '100%', objectFit: 'cover', borderRadius: '10px'}}/>
                  }
                </>
              }
            </Segment>

          }
          <div>
            {
              answers.map((answer, idx) => <AnswerCard reload={(uuid) => {
                getTeamReport(uuid)}} answer={answer} key={idx}/>
              )
            }
          </div>
        </Grid.Column>
      </Grid>
    </div>)
}

export default Report;