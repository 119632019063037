import React from 'react';
import { useLocation } from 'react-router-dom';

const PaymentFail = () => {
  const query = URLSearchParams(useLocation().search);
  const errorCode = query.get('code');
  const errorMessage = query.get('message');

  return (
    <div>
      <h1>Query String Parameters</h1>
      <p>Error Code: {errorCode}</p>
      <p>Error Message: {errorMessage}</p>
    </div>
  );
};

export default PaymentFail;
