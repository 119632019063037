import {Component, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {Button, Form} from "semantic-ui-react";
import axios from "axios";


function JoinIn(props){
  const [name, setName, removeName] = useState();
  const [email, setEmail, removeEmail] = useState();
  const [pw, setPw, removePw] = useState();
  const [pw2, setPw2, removePw2] = useState();
  const {formatMessage} = useIntl();
  const pw_diff_error = formatMessage({id: 'pw_diff'});

  const handleName = (e) =>{
    setName(e.target.value)
  }

  const handleEmail = (e) =>{
    setEmail(e.target.value)
  }

  const handlePw = (e) =>{
    setPw(e.target.value)
  }

  const handlePw2 = (e) =>{
    setPw2(e.target.value)
  }

  const sendData = async () =>{
    const emailVal = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,5}$/;
    if (!emailVal.test(email)) return alert('이메일 형식이 올바르지 않습니다.');

    if(pw===pw2){
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/user/signup`, {
          email: email,
          name: name,
          raw_password: pw,
        })
        alert('가입했습니다. 이메일 인증이 완료되야 사용가능합니다. 메일함을 확인해주세요.')
        window.location.href = "/"
      } catch (e) {
        alert('문제가 발생했습니다.')
      }
    }
    else{
      alert(pw_diff_error);
    }
  }

  return (
      <div>
        <h2><FormattedMessage id="sign_up"/></h2>
        <Form className={"mv-8"}>
          <Form.Field required>
            <label><FormattedMessage id="user_name"/></label>
            <FormattedMessage id= "input_name">
              {text => <input type="text" name="name" placeholder={text} value={name} onChange={handleName}/> }
            </FormattedMessage>
          </Form.Field>
          <Form.Field required>
            <label>ID</label>
            <FormattedMessage id= "input_email">
              {text => <input type="text" name="ID" placeholder={text} value={email} onChange={handleEmail}/> }
            </FormattedMessage>
          </Form.Field>
          <Form.Field required>
            <label>PW</label>
            <FormattedMessage id= "input_pw">
              {text => <input type="password" name="ID" placeholder={text} value={pw} onChange={handlePw}/> }
            </FormattedMessage>
          </Form.Field>
          <Form.Field required>
            <label><FormattedMessage id="check_pw"/></label>
            <FormattedMessage id= "confirm_pw">
              {text => <input type="password" name="ID" placeholder={text} value={pw2} onChange={handlePw2}/> }
            </FormattedMessage>
          </Form.Field>
        </Form>
        <Button onClick={sendData}><FormattedMessage id="sign_up"/></Button>
      </div>
  )

}

export default JoinIn;